button.react-calendar__tile.react-calendar__month-view__days__day {
    .react-calendar-tooltip {
        //position: absolute;
        //bottom: calc(100% + 6px);
        //left: 50%;
        position: fixed;
        width: 120px;
        //margin-left: -60px;
        display: none;
        background-color: rgba($subsidiary-dark, 0.85);
        color: #ffffff;
        text-align: center;
        border-radius: 0.3em;
        padding: 0.4em;
        z-index: 99;
        margin-top: -60px;
    }

    .react-calendar-availability {
        width: 8px;
        height: 8px;
        position: absolute;
        bottom: 0.4em;
        right: auto;
        left: 50%;
        margin-left: -4px;
        border-radius: 50%;
        border: 0;
        background-color: $subsidiary-lightest;

        &--almost-full {
            background: $warning-light;
        }

        &--full {
            background: $danger;
        }
    }
}

.react-calendar {
    width: 100%;
    background-color: color("white");
    color: $body-color;
    line-height: 1.125em;
    padding: 0 1rem 0 1rem;
    margin-bottom: 25px;

    &,
    & *,
    & *:before,
    & *:after {
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    button {
        &:enabled {
            &:hover {
                cursor: pointer;
            }
        }
    }

    &__navigation {
        height: 32px;
        margin-bottom: 3px;
        //border-bottom: 1px solid $subsidiary-lightest;
        color: $body-color;

        button {
            min-width: 32px;
            background: none;
            margin: 0;
            border: 0;
            outline: none;
            height: auto;

            &:enabled {
                &:hover {
                    color: $primary;
                }
            }
        }

        &__label {
            font-size: 15px;
            font-weight: 700;
            color: $body-color;
            position: relative;
            top: 2px;
        }

        &__arrow {
            font-size: 26px;
            font-weight: 700;
            color: $body-color;
        }

        &__next2-button,
        &__prev2-button {
            display: none;
        }
    }

    &__month-view {
        &__weekdays {
            text-align: center;
            font-size: 0.9em;
            border-radius: $border-radius;
            overflow: hidden;
            margin-bottom: 3px;

            &__weekday {
                padding: 0.5em;
                background-color: $subsidiary-lightest;

                abbr[title] {
                    color: $subsidiary-lighter;
                    text-decoration: none;
                    cursor: default;
                }
            }
        }

        &__weekNumbers {
            .react-calendar__tile {
                color: $body-color;
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 0.75em;
                padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
            }
        }

        &__days {
            &__day {
                &--neighboringMonth {
                    color: $subsidiary-lighter !important;
                }
            }
        }
    }

    &__year-view,
    &__decade-view,
    &__century-view {
        .react-calendar__tile {
            color: $body-color;
        }
    }

    &__tile {
        max-width: 100%;
        text-align: center;
        padding: 0.8em 0.5em;
        background: none;
        border-radius: $border-radius;
        border: 1px solid transparent;
        position: relative;
        overflow: auto !important;
        color: $body-color;

        &.react-calendar__month-view__days__day {
            padding: 0.6em 0.5em 0.6em 0.5em;

        }

        &:disabled {
            color: $subsidiary-lighter;
        }

        &:enabled {
            &:hover,
            &:focus {
                background-color: $subsidiary-lightest;
                border-color: rgba($subsidiary-lightest, 0.8);
            }
        }

        &--now {
            background-color: $subsidiary-lightest;
        }

        &--active {
            background-color: $white;
            color: $primary;
            border-color: $primary;

            &:enabled {
                &:hover,
                &:focus {
                    background-color: $white;
                    color: $primary;
                    box-shadow: none;
                    border-color: $primary-dark;
                }

                &:active {
                }
            }
        }
    }
}
