.pricebadge {
    font-size: 1.25rem;
    color: $primary;
    font-weight: 600;
    vertical-align: middle;
    padding-left: 0.2rem;
    padding-right: 0.2rem;

    &:not(:last-child) {
        margin-right: 0.4rem;
    }
}
