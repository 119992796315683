/*!
 * Reservi
 */
@import url(//fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,600,700,900,400italic);
@import url(~@fullcalendar/core/main.css);
@import url(~@fullcalendar/daygrid/main.css);
@import url(~@fullcalendar/timegrid/main.css);
.form-group.is-invalid .form-control {
  border-color: #dc3545; }

.form-group.is-invalid .form-select > div {
  border-color: #dc3545; }

.form-group.is-invalid .c-checkbox span, .form-group.is-invalid .c-radio span {
  border-color: #dc3545; }

.form-group.is-invalid .invalid-feedback {
  display: block;
  font-size: 100%; }

/*
$info:                  $primary;
$info-light:            $primary-light;
$info-lightest:         $primary-lightest;
$info-dark:             $primary-dark;*/
/* ========================================================================
     Component: animate
 ========================================================================== */
@-webkit-keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

@keyframes fadeOutUpShort {
  0% {
    opacity: 1; }
  100% {
    opacity: 0;
    -webkit-transform: translate3d(0, -20px, 0);
    -ms-transform: translate3d(0, -20px, 0);
    transform: translate3d(0, -20px, 0); } }

.fadeOutUpShort {
  -webkit-animation-name: fadeOutUpShort;
  animation-name: fadeOutUpShort; }

@-webkit-keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInUpShort {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(0, 20px, 0);
    -ms-transform: translate3d(0, 20px, 0);
    transform: translate3d(0, 20px, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInUpShort {
  -webkit-animation-name: fadeInUpShort;
  animation-name: fadeInUpShort; }

@-webkit-keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

@keyframes zoomInShort {
  0% {
    opacity: 0;
    -webkit-transform: scale3d(0.95, 0.95, 0.95);
    -ms-transform: scale3d(0.95, 0.95, 0.95);
    transform: scale3d(0.95, 0.95, 0.95); }
  50% {
    opacity: 1; } }

.zoomInShort {
  -webkit-animation-name: zoomInShort;
  animation-name: zoomInShort; }

@-webkit-keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

@keyframes zoomBack {
  0% {
    opacity: 1; }
  50% {
    opacity: 0;
    -webkit-transform: scale3d(0.5, 0.5, 0.5);
    -ms-transform: scale3d(0.5, 0.5, 0.5);
    transform: scale3d(0.5, 0.5, 0.5); }
  100% {
    opacity: 0; } }

.zoomBack {
  -webkit-animation-name: zoomBack;
  animation-name: zoomBack; }

@-webkit-keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    transform: none; } }

@keyframes fadeInLeft {
  0% {
    opacity: 0;
    -webkit-transform: translate3d(-20px, 0, 0);
    -ms-transform: translate3d(-20px, 0, 0);
    transform: translate3d(-20px, 0, 0); }
  100% {
    opacity: 1;
    -webkit-transform: none;
    -ms-transform: none;
    transform: none; } }

.fadeInLeft {
  -webkit-animation-name: fadeInLeft;
  animation-name: fadeInLeft; }

.rag-fadeIn-enter {
  animation: fadeIn 0.3s; }

.rag-fadeIn-exit {
  display: none; }

.rag-fadeInRight-enter {
  animation: fadeInRight 0.3s; }

.rag-fadeInRight-exit {
  display: none; }

.rag-fadeInLeft-enter {
  animation: fadeInLeft 0.3s; }

.rag-fadeInLeft-exit {
  display: none; }

/* ========================================================================
     Component: print
 ========================================================================== */
.print-source {
  display: none; }

body > .print-source {
  display: block; }

@media print {
  .print-source {
    display: block; }
  .sidebar,
  .topnavbar,
  .offsidebar,
  .btn {
    display: none !important;
    width: 0 !important;
    height: 0 !important; }
  .wrapper,
  .wrapper .section-container,
  .content-wrapper {
    margin: 0 !important;
    /* remove margin used for sidebar and expand the content */
    padding: 0 !important;
    width: 100% !important; }
  .content-wrapper {
    overflow: hidden !important; } }

/* ========================================================================
         Component: utils
 ========================================================================== */
.bg-lightest {
  background-color: #edf2f9; }

.b0 {
  border-width: 0 !important; }

.bl0 {
  border-left-width: 0 !important; }

.br0 {
  border-right-width: 0 !important; }

.bt0 {
  border-top-width: 0 !important; }

.bb0 {
  border-bottom-width: 0 !important; }

.br, .b, .ba {
  border-right: 1px solid rgba(0, 0, 0, 0.12); }

.bl, .b, .ba {
  border-left: 1px solid rgba(0, 0, 0, 0.12); }

.bt, .b, .ba {
  border-top: 1px solid rgba(0, 0, 0, 0.12); }

.bb, .b, .ba {
  border-bottom: 1px solid rgba(0, 0, 0, 0.12); }

.text-primary {
  color: #F95C1E !important; }

.text-success {
  color: #27c24c !important; }

.text-info {
  color: #23b7e5 !important; }

.text-warning {
  color: #FF7F44 !important; }

.text-danger {
  color: #f05050 !important; }

.text-white {
  color: #fff !important; }

.text-inverse {
  color: #131e26 !important; }

.text-alpha {
  color: rgba(255, 255, 255, 0.5) !important; }

.text-pink {
  color: #f532e5 !important; }

.text-purple {
  color: #7266ba !important; }

.text-dark {
  color: #3a3f51 !important; }

.text-alpha-inverse {
  color: rgba(0, 0, 0, 0.5) !important; }

.text-green {
  color: #37bc9b !important; }

.text-yellow {
  color: #fad732 !important; }

.text-gray-darker {
  color: #26314A !important; }

.text-gray-dark {
  color: #55657F !important; }

.text-gray {
  color: #dde6e9 !important; }

.text-gray-light {
  color: #e3e7ea !important; }

.text-gray-lighter {
  color: #dce7ed !important; }

.text-inherit {
  color: inherit !important; }

.text-sm {
  font-size: 0.74375rem; }

.text-md {
  font-size: 1.6625rem; }

.text-lg {
  font-size: 2.625rem; }

.text-nowrap {
  white-space: nowrap; }

.text-thin {
  font-weight: 100 !important; }

.text-normal {
  font-weight: normal !important; }

.text-bold {
  font-weight: bold !important; }

.inline {
  display: inline-block !important; }

.block-center {
  margin: 0 auto; }

.bg-primary {
  background-color: #F95C1E;
  color: #fff !important; }

.bg-primary-light {
  background-color: #FF7F44;
  color: #fff !important; }

.bg-primary-dark {
  background-color: #E54607;
  color: #fff !important; }

.bg-primary small {
  color: inherit; }

.bg-success {
  background-color: #27c24c;
  color: #fff !important; }

.bg-success-light {
  background-color: #43d967;
  color: #fff !important; }

.bg-success-dark {
  background-color: #1e983b;
  color: #fff !important; }

.bg-success small {
  color: inherit; }

.bg-info {
  background-color: #F95C1E;
  color: #fff !important; }

.bg-info-light {
  background-color: #FF7F44;
  color: #fff !important; }

.bg-info-dark {
  background-color: #E54607;
  color: #fff !important; }

.bg-info small {
  color: inherit; }

.bg-warning {
  background-color: #FF7F44;
  color: #fff !important; }

.bg-warning-light {
  background-color: #ffa277;
  color: #fff !important; }

.bg-warning-dark {
  background-color: #ff5c11;
  color: #fff !important; }

.bg-warning small {
  color: inherit; }

.bg-danger {
  background-color: #f05050;
  color: #fff !important; }

.bg-danger-light {
  background-color: #f47f7f;
  color: #fff !important; }

.bg-danger-dark {
  background-color: #ec2121;
  color: #fff !important; }

.bg-danger small {
  color: inherit; }

.bg-green {
  background-color: #37bc9b;
  color: #fff !important; }

.bg-green-light {
  background-color: #58ceb1;
  color: #fff !important; }

.bg-green-dark {
  background-color: #2b957a;
  color: #fff !important; }

.bg-green small {
  color: inherit; }

.bg-pink {
  background-color: #f532e5;
  color: #fff !important; }

.bg-pink-light {
  background-color: #f763eb;
  color: #fff !important; }

.bg-pink-dark {
  background-color: #e90bd6;
  color: #fff !important; }

.bg-pink small {
  color: inherit; }

.bg-purple {
  background-color: #7266ba;
  color: #fff !important; }

.bg-purple-light {
  background-color: #9289ca;
  color: #fff !important; }

.bg-purple-dark {
  background-color: #564aa3;
  color: #fff !important; }

.bg-purple small {
  color: inherit; }

.bg-inverse {
  background-color: #131e26;
  color: #fff !important; }

.bg-inverse-light {
  background-color: #243948;
  color: #fff !important; }

.bg-inverse-dark {
  background-color: #020304;
  color: #fff !important; }

.bg-inverse small {
  color: inherit; }

.bg-yellow {
  background-color: #fad732;
  color: #fff !important; }

.bg-yellow-light {
  background-color: #fbe164;
  color: #fff !important; }

.bg-yellow-dark {
  background-color: #f3ca06;
  color: #fff !important; }

.bg-yellow small {
  color: inherit; }

.bg-white {
  background-color: #fff;
  color: inherit !important; }

.bg-gray-darker {
  background-color: #26314A;
  color: #fff !important; }

.bg-gray-dark {
  background-color: #55657F;
  color: #fff !important; }

.bg-gray {
  background-color: #dde6e9;
  color: #26314A !important; }

.bg-gray-light {
  background-color: #e3e7ea;
  color: #26314A !important; }

.bg-gray-lighter {
  background-color: #dce7ed;
  color: #26314A !important; }

.bg-transparent {
  background-color: transparent !important; }

.bg-cover {
  background-size: cover; }

.thumb8 {
  width: 8px !important;
  height: 8px !important; }

.thumb16 {
  width: 16px !important;
  height: 16px !important; }

.thumb24 {
  width: 24px !important;
  height: 24px !important; }

.thumb32 {
  width: 32px !important;
  height: 32px !important; }

.thumb48 {
  width: 48px !important;
  height: 48px !important; }

.thumb64 {
  width: 64px !important;
  height: 64px !important; }

.thumb80 {
  width: 80px !important;
  height: 80px !important; }

.thumb96 {
  width: 96px !important;
  height: 96px !important; }

.thumb128 {
  width: 128px !important;
  height: 128px !important; }

.align-middle {
  vertical-align: middle; }

.align-top {
  vertical-align: top; }

.align-bottom {
  vertical-align: bottom; }

.bg-center {
  background-position: center center;
  background-size: cover; }

.list-icon em {
  font-size: 14px;
  width: 40px;
  vertical-align: middle;
  margin: 0;
  display: inline-block;
  text-align: center;
  transition: all 0.2s;
  line-height: 30px; }
  @media (prefers-reduced-motion: reduce) {
    .list-icon em {
      transition: none; } }

.list-icon div:hover em {
  transform: scale(3, 3); }

.d-table-fixed {
  table-layout: fixed; }

.wd-xxs {
  width: 60px; }

.wd-xs {
  width: 90px; }

.wd-sm {
  width: 150px; }

.wd-sd {
  width: 200px; }

.wd-md {
  width: 240px; }

.wd-lg {
  width: 280px; }

.wd-xl {
  width: 320px; }

.wd-xxl {
  width: 360px; }

.wd-wide {
  width: 100%; }

.wd-auto {
  width: auto; }

.wd-zero {
  width: 0; }

.clickable {
  cursor: pointer; }

.abs-center-container {
  position: relative; }

.abs-center {
  height: 50%;
  overflow: auto;
  margin: auto;
  position: absolute;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0; }
  .abs-center.abs-fixed {
    position: fixed;
    z-index: 999; }
  .abs-center.abs-right {
    left: auto;
    right: 20px;
    text-align: right; }
  .abs-center.abs-left {
    right: auto;
    left: 20px;
    text-align: left; }

@media (max-height: 720px) {
  .abs-center {
    position: relative; } }

.link-unstyled {
  text-decoration: none !important;
  outline: none !important; }

.no-resize {
  resize: none;
  max-width: 100%;
  min-width: 100%; }

.ie-fix-flex {
  -ms-flex: 0 0 auto; }

/* ========================================================================
     Component: layout
 ========================================================================== */
html {
  /* $replace rtl */
  direction: ltr;
  height: 100%;
  -ms-touch-action: manipulation;
  touch-action: manipulation;
  font-size: 16px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

html, body, #app {
  height: 100%; }

.hidden {
  display: none !important; }

.wrapper {
  position: relative;
  width: 100%;
  height: auto;
  min-height: 100%;
  overflow-x: hidden; }
  .wrapper.ng-leave {
    display: none; }
  .wrapper .aside-container {
    position: absolute;
    width: 275px;
    top: 0;
    left: 0;
    bottom: 0;
    z-index: 116;
    backface-visibility: hidden;
    background-color: #55657F;
    box-shadow: 0 0 5px rgba(38, 49, 74, 0.2); }
    .wrapper .aside-container .aside-inner {
      padding-top: 65px;
      height: 100%;
      width: 275px;
      overflow: hidden; }
    .wrapper .aside-container .nav-floating {
      left: inherit;
      margin-left: 275px;
      z-index: 1110;
      min-width: 190px;
      overflow: auto; }
  .wrapper .section-container {
    position: relative;
    height: 100%;
    min-height: calc(100vh - 25px - 65px);
    margin-left: 0;
    z-index: 111;
    background-color: #ffffff;
    margin-bottom: 25px !important;
    display: flex;
    flex-flow: row wrap; }
    .wrapper .section-container > * {
      flex: 0 1 auto;
      width: 100%; }
    .wrapper .section-container > div:not([class]) {
      min-height: 100%; }
  .wrapper .footer-container {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    height: 25px;
    z-index: 109;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    padding-right: 1rem;
    background-color: #edf2f9;
    color: #94A0B2;
    border-top: 1px solid #ECF3FF; }
    .wrapper .footer-container p {
      margin: 0; }
  .wrapper .topnavbar-wrapper {
    box-shadow: 0 0 5px rgba(38, 49, 74, 0.2); }

.aside-collapsed-text .wrapper .aside-container {
  box-shadow: none; }

.aside-collapsed-text .wrapper .section-container {
  border-left: 0;
  border-top: 0; }

.aside-collapsed-text .wrapper .topnavbar-wrapper {
  box-shadow: none; }

.modal-open .wrapper {
  z-index: 0; }

.content-wrapper {
  padding: 15px;
  height: 100%;
  width: 100%; }
  .content-wrapper.content-wrapper-grow {
    display: flex;
    flex-flow: column nowrap;
    padding-bottom: 0; }
    .content-wrapper.content-wrapper-grow > * {
      flex: 0 1 auto; }
    .content-wrapper.content-wrapper-grow > .container-grow {
      flex: 1 1 auto; }
  .content-wrapper .unwrap {
    margin: -15px; }
    @media (min-width: 768px) {
      .content-wrapper .unwrap {
        margin: -20px; } }
  .content-wrapper .content-heading {
    display: flex;
    align-items: center;
    font-size: 1.5rem;
    line-height: 1.1;
    color: #929292;
    margin: -15px;
    margin-bottom: 20px;
    padding: 15px;
    font-weight: normal;
    background-color: #edf2f9;
    border-bottom: 1px solid #cfdbe2; }
    .content-wrapper .content-heading small {
      display: block;
      font-size: 12px;
      color: #909FA7; }
  .content-wrapper .container,
  .content-wrapper .container-fluid {
    padding-left: 0;
    padding-right: 0; }
  .content-wrapper .container-content {
    background-color: #ffffff;
    margin-left: -15px;
    margin-right: -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-top: 15px;
    padding-bottom: 15px;
    width: auto; }
  @media (min-width: 768px) {
    .content-wrapper {
      padding: 15px 15px 25px 15px; }
      .content-wrapper .content-heading {
        margin: -15px;
        margin-bottom: 15px;
        padding: 15px; }
        .content-wrapper .content-heading button,
        .content-wrapper .content-heading .btn {
          margin: 0; } }

@media (min-width: 768px) {
  body {
    min-height: 100%; }
  .wrapper .section-container.has-sidebar-right {
    margin-right: 275px; }
    .wrapper .section-container.has-sidebar-right + .offsidebar {
      z-index: 1; } }

@media (max-width: 767.98px) {
  .wrapper .aside-container {
    margin-left: -275px; }
  .aside-toggled .wrapper .section-container,
  .aside-toggled .wrapper .footer-container {
    margin-left: 275px; }
  .aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .wrapper {
    backface-visibility: hidden; }
    .csstransforms3d .wrapper .section-container,
    .csstransforms3d .wrapper .footer-container {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
      transition: transform .3s ease; }
    .csstransforms3d .wrapper .aside-container {
      margin-left: 0;
      transform: translate3d(-275px, 0, 0);
      transition: transform .3s ease; }
  .csstransforms3d .aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-toggled .wrapper .footer-container {
    transform: translate3d(275px, 0, 0); }
  .csstransforms3d .aside-toggled .wrapper .aside-container {
    transform: translate3d(0, 0, 0); } }

@media (max-width: 767.98px) {
  .aside-collapsed .wrapper .aside-container {
    margin-left: -70px; }
  .aside-collapsed.aside-toggled .wrapper .section-container,
  .aside-collapsed.aside-toggled .wrapper .footer-container {
    margin-left: 70px; }
  .aside-collapsed.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed .wrapper {
    backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed .wrapper .section-container,
    .csstransforms3d .aside-collapsed .wrapper .footer-container {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
      transition: transform .3s ease; }
    .csstransforms3d .aside-collapsed .wrapper .aside-container {
      margin-left: 0;
      transform: translate3d(-70px, 0, 0);
      transition: transform .3s ease; }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .footer-container {
    transform: translate3d(70px, 0, 0); }
  .csstransforms3d .aside-collapsed.aside-toggled .wrapper .aside-container {
    transform: translate3d(0, 0, 0); } }

.aside-collapsed {
  overflow-y: auto; }
  .aside-collapsed .wrapper .aside-container,
  .aside-collapsed .wrapper .aside-container .aside-inner {
    width: 70px; }
  .aside-collapsed .wrapper .aside-container .nav-floating {
    margin-left: 70px; }

@media (min-width: 768px) {
  .aside-collapsed .wrapper .section-container,
  .aside-collapsed .wrapper .footer-container {
    margin-left: 70px; } }

@media (max-width: 767.98px) {
  .aside-collapsed-text .wrapper .aside-container {
    margin-left: -90px; }
  .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    margin-left: 90px; }
  .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    margin-left: 0; }
  .csstransforms3d .aside-collapsed-text .wrapper {
    backface-visibility: hidden; }
    .csstransforms3d .aside-collapsed-text .wrapper .section-container,
    .csstransforms3d .aside-collapsed-text .wrapper .footer-container {
      margin-left: 0;
      transform: translate3d(0, 0, 0);
      transition: transform .3s ease; }
    .csstransforms3d .aside-collapsed-text .wrapper .aside-container {
      margin-left: 0;
      transform: translate3d(-90px, 0, 0);
      transition: transform .3s ease; }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .section-container,
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .footer-container {
    transform: translate3d(90px, 0, 0); }
  .csstransforms3d .aside-collapsed-text.aside-toggled .wrapper .aside-container {
    transform: translate3d(0, 0, 0); } }

.aside-collapsed-text {
  overflow-y: auto; }
  .aside-collapsed-text .wrapper .aside-container,
  .aside-collapsed-text .wrapper .aside-container .aside-inner {
    width: 90px; }
  .aside-collapsed-text .wrapper .aside-container .nav-floating {
    margin-left: 90px; }

@media (min-width: 768px) {
  .aside-collapsed-text .wrapper .section-container,
  .aside-collapsed-text .wrapper .footer-container {
    margin-left: 90px; } }

@media (max-width: 1140px) {
  .layout-fixed.aside-toggled, .layout-fixed.offsidebar-open {
    overflow-y: hidden; } }

.layout-fixed .wrapper {
  /* only applied to sidebar */ }
  .layout-fixed .wrapper .topnavbar-wrapper {
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 121; }
  .layout-fixed .wrapper .aside-container,
  .layout-fixed .wrapper .offsidebar {
    position: fixed; }
  .layout-fixed .wrapper .aside-container {
    /* safari fix */
    height: 1px;
    min-height: 100%;
    /* ios blanks space fix */ }
    .layout-fixed .wrapper .aside-container .aside-inner {
      position: fixed;
      top: 0;
      bottom: 0; }
  .layout-fixed .wrapper .section-container {
    margin-top: 65px; }

/* IE10+ hack: safari fix breaks ie so we need to target ie only to restore */
_:-ms-lang(x),
.layout-fixed .wrapper .aside-container .aside-inner {
  position: static !important; }

@media (min-width: 992px) {
  .layout-boxed {
    overflow: auto !important; }
    .layout-boxed .wrapper {
      margin: 0 auto;
      overflow: hidden;
      box-shadow: 0 0 13px rgba(0, 0, 0, 0.25); }
      .layout-boxed .wrapper .offsidebar {
        position: absolute !important; }
      .layout-boxed .wrapper .aside-container {
        left: inherit; }
      .layout-boxed .wrapper,
      .layout-boxed .wrapper .topnavbar-wrapper {
        width: 970px; }
    .layout-boxed.layout-fixed .wrapper .aside-container .aside-inner {
      left: inherit; } }

@media (min-width: 1200px) {
  .layout-boxed .wrapper,
  .layout-boxed .wrapper .topnavbar-wrapper {
    width: 1140px; } }

.sidebar-backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 275px;
  z-index: 112; }
  .aside-collapsed .sidebar-backdrop {
    left: 70px; }
  .aside-collapsed-text .sidebar-backdrop {
    left: 90px; }

/* ========================================================================
   Component: layout-extra
 ========================================================================== */
.hidden-footer .wrapper .footer-container {
  display: none; }

.hidden-footer .wrapper .section-container {
  margin-bottom: 0 !important; }

.layout-fs .wrapper .section-container {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 25px;
  top: 65px;
  height: auto;
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  overflow-y: auto;
  -webkit-overflow-scrolling: touch;
  overflow: hidden; }
  .layout-fs .wrapper .section-container .content-wrapper {
    width: 100%;
    height: 100%;
    padding: 0; }
    .layout-fs .wrapper .section-container .content-wrapper > * {
      width: 100%;
      height: 100%; }

.layout-fs.hidden-footer .wrapper .section-container {
  bottom: 0; }

.layout-h .wrapper .section-container {
  max-width: 1140px;
  margin-left: auto;
  margin-right: auto; }
  .layout-h .wrapper .section-container .content-wrapper .content-heading {
    display: none; }

.layout-h .wrapper .section-container {
  margin-top: 0; }

.layout-h .wrapper .offsidebar {
  margin-top: 65px; }

.layout-h .wrapper .footer-container {
  margin: 0; }

.layout-h.layout-fixed .wrapper .section-container,
.layout-h.layout-fixed .wrapper .offsidebar {
  margin-top: 65px; }

.aside-float .wrapper {
  box-shadow: 0 0 0 #000; }
  .aside-float .wrapper .footer-container {
    border: 0; }
  .aside-float .wrapper .aside-container {
    padding: 15px 0;
    background: transparent; }
    .aside-float .wrapper .aside-container .aside-inner {
      height: 100%;
      top: 15px;
      bottom: 15px;
      left: inherit; }
    .aside-float .wrapper .aside-container .sidebar {
      border: 1px solid #e3e7ea; }
    .aside-float .wrapper .aside-container .sidebar:after {
      display: none; }
  @media (min-width: 768px) {
    .aside-float .wrapper .section-container {
      padding-left: 15px; }
    .aside-float .wrapper .footer-container {
      left: 15px; } }
  .aside-float .wrapper .content-wrapper {
    border: 0; }
    .aside-float .wrapper .content-wrapper .content-heading {
      padding-top: 30px;
      border: 0;
      background-color: transparent; }
    .aside-float .wrapper .content-wrapper .unwrap {
      margin: 0 !important; }

.aside-float.aside-toggled .wrapper .aside-container {
  transition: delay(0.05s); }
  .aside-float.aside-toggled .wrapper .aside-container .aside-inner {
    margin-left: 15px; }

.aside-float.aside-toggled .wrapper .section-container {
  padding-left: 15px; }

.aside-float.aside-toggled .wrapper .footer-container {
  left: 15px; }

@media (min-width: 768px) {
  .aside-float.aside-collapsed .topnavbar .navbar-header {
    width: 100px; }
  .aside-float.aside-collapsed-text .topnavbar .navbar-header {
    width: 120px; }
  .aside-float.layout-fs .wrapper .section-container .content-wrapper {
    padding: 0 0 0 20px; }
  .aside-float .wrapper .aside-container .aside-inner {
    margin-left: 15px; } }

@media (min-width: 992px) {
  .aside-float.layout-boxed .wrapper .aside-container .aside-inner {
    margin-left: 0; }
  .aside-float.layout-boxed .wrapper .section-container {
    padding-left: 0; } }

.aside-float.aside-toggled.layout-fs .wrapper .section-container .content-wrapper {
  padding: 0 0 0 20px; }

/* ========================================================================
   Component: layout-animation.less
 ========================================================================== */
.wrapper .aside-container {
  transition: width .2s cubic-bezier(0.35, 0, 0.25, 1), translate .2s cubic-bezier(0.35, 0, 0.25, 1); }
  @media (prefers-reduced-motion: reduce) {
    .wrapper .aside-container {
      transition: none; } }

.aside-inner,
.navbar-header,
.sidebar > .sidebar-nav > li {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.wrapper .section-container {
  transition: margin-left 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.sidebar > .sidebar-nav .badge {
  animation: fadeInRight 1s;
  animation-fill-mode: both; }

.aside-collapsed .sidebar > .sidebar-nav .badge,
.aside-collapsed-text .sidebar > .sidebar-nav .badge {
  animation: fadeIn 1s; }

.sidebar li > a,
.sidebar li > .nav-item,
.sidebar > .sidebar-nav > .nav-heading {
  white-space: nowrap; }

.aside-collapsed .user-block-picture,
.aside-collapsed-text .user-block-picture {
  transition: width 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  transition: padding 0.2s cubic-bezier(0.35, 0, 0.25, 1); }

/* ========================================================================
     Component: top-navbar
 ========================================================================== */
.topnavbar {
  -webkit-backface-visibility: hidden;
  /* fixes chrome jump */
  margin-bottom: 0;
  border-radius: 0;
  z-index: 1050;
  border: 0;
  padding: 0;
  min-height: 65px;
  background-color: #ffffff;
  box-shadow: 0 0 5px rgba(38, 49, 74, 0.2); }
  @media (min-width: 768px) {
    .topnavbar .navbar-header {
      width: 275px;
      text-align: center; }
      .topnavbar .navbar-header .navbar-brand {
        width: 100%; } }

.topnavbar {
  position: relative; }
  .topnavbar .navbar-header {
    background-color: transparent; }
  .topnavbar .navbar-header {
    position: relative;
    z-index: 11;
    padding-left: 2rem; }
    @media (min-width: 768px) {
      .topnavbar .navbar-header {
        padding-left: 0; } }
    .topnavbar .navbar-header .navbar-brand {
      padding: 0; }
    .topnavbar .navbar-header .brand-logo > img,
    .topnavbar .navbar-header .brand-logo-collapsed > img {
      margin: 0 auto; }
    .topnavbar .navbar-header .brand-logo {
      display: block;
      padding-left: 1.1rem;
      padding-right: 1rem;
      padding-bottom: 15px;
      padding-top: 9px;
      text-align: left; }
    .topnavbar .navbar-header .brand-logo-collapsed {
      display: none;
      padding: 6px 15px; }
  .topnavbar .dropdown {
    position: static; }
    .topnavbar .dropdown .dropdown-menu {
      position: absolute;
      margin-top: 0;
      top: auto;
      left: 0;
      right: 0; }
  @media (min-width: 992px) {
    .topnavbar .dropdown {
      position: relative; }
      .topnavbar .dropdown .dropdown-menu {
        top: 64px;
        left: 0;
        right: auto; }
      .topnavbar .dropdown .dropdown-menu-right {
        right: 0;
        left: auto; } }
  .topnavbar .navbar-nav > .nav-item > .navbar-text {
    color: #26314A; }
  .topnavbar .navbar-nav > .nav-item > .nav-link {
    padding: 1.1rem .95rem;
    font-size: 1.1rem; }
  .topnavbar .navbar-nav > .nav-item > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link {
    color: #26314A; }
    .topnavbar .navbar-nav > .nav-item > .nav-link:hover, .topnavbar .navbar-nav > .nav-item > .nav-link:focus,
    .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
    .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
      color: #FF7F44; }
  .topnavbar .navbar-nav > .nav-item.active > .nav-link, .topnavbar .navbar-nav > .nav-item.active > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.active > .nav-link:focus,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover,
  .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    background-color: transparent; }
  .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 20;
    font-size: 16px;
    line-height: 65px;
    color: #fff;
    padding-top: 0;
    padding-bottom: 0;
    transition: color 0.3s ease; }
    @media (prefers-reduced-motion: reduce) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        transition: none; } }
    @media (min-width: 768px) {
      .topnavbar .navbar-nav > li > [data-toggle='navbar-search'] {
        color: #26314A; } }
  @media (max-width: 767.98px) {
    .topnavbar .navbar-text {
      margin: 10px; } }

.layout-h .topnavbar .navbar-header {
  display: flex;
  width: 100%;
  padding: 0;
  text-align: left; }
  @media (min-width: 992px) {
    .layout-h .topnavbar .navbar-header {
      width: auto; } }
  .layout-h .topnavbar .navbar-header .navbar-brand {
    min-height: 65px; }

.layout-h .topnavbar .navbar-toggler {
  border: 0;
  margin-left: auto; }
  .layout-h .topnavbar .navbar-toggler .navbar-toggler-icon {
    background-image: url("data:image/svg+xml;charset=utf8,<svg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'><path stroke='rgba(255, 255, 255, 0.75)' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/></svg>"); }

@media (max-width: 991.98px) {
  .layout-h .topnavbar .dropdown-menu {
    left: 0 !important;
    right: 0 !important; } }

.layout-h .topnavbar .navbar-form {
  left: 0; }

@media (max-width: 767.98px) {
  .sidebar-toggle {
    position: absolute !important;
    top: 2px;
    left: 0;
    z-index: 3001; }
    .sidebar-toggle > em {
      color: white; } }

.topnavbar .navbar-form {
  position: absolute;
  top: -100%;
  left: 0;
  right: 0;
  margin: 0;
  padding: 0;
  height: 65px;
  z-index: 9001;
  transition: all .3s;
  border: 0;
  border-bottom: 1px solid #e1e2e3; }
  .topnavbar .navbar-form .form-group {
    height: 100%;
    width: 100%; }
  .topnavbar .navbar-form .form-control {
    height: 100%;
    border: 0;
    border-radius: 0;
    width: 100%; }
  .topnavbar .navbar-form.open {
    top: 0; }
  .topnavbar .navbar-form .navbar-form-close {
    position: absolute;
    height: 30px;
    cursor: pointer;
    top: 50%;
    right: 0;
    margin-top: -15px;
    line-height: 30px;
    margin-right: 10px;
    color: #c1c2c3;
    font-size: 1.5em;
    pointer-events: auto; }

@media (min-width: 576px) {
  .topnavbar .navbar-form {
    left: 275px; } }

@media (min-width: 992px) {
  .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
    box-shadow: 0 -3px 0 #f0f0f0 inset;
    transition: all 0.2s; } }
  @media (min-width: 992px) and (prefers-reduced-motion: reduce) {
    .topnavbar .navbar-nav > .nav-item.show > .nav-link, .topnavbar .navbar-nav > .nav-item.show > .nav-link:hover, .topnavbar .navbar-nav > .nav-item.show > .nav-link:focus {
      transition: none; } }

@media (min-width: 768px) {
  .aside-collapsed .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed .topnavbar .navbar-header {
    width: 70px; }
  .aside-collapsed .topnavbar .navbar-form {
    left: 70px; } }

@media (min-width: 768px) {
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo {
    display: none; }
  .aside-collapsed-text .topnavbar .navbar-header .brand-logo-collapsed {
    display: block; }
  .aside-collapsed-text .topnavbar .navbar-header {
    width: 90px; }
  .aside-collapsed-text .topnavbar .navbar-form {
    left: 90px; } }

/* ========================================================================
     Component: sidebar
 ========================================================================== */
.sidebar {
  height: 100%;
  padding-bottom: 20px;
  background-color: #ffffff;
  overflow-x: hidden;
  -webkit-overflow-scrolling: touch; }
  .sidebar:after {
    content: "";
    background: rgba(0, 0, 0, 0.15);
    position: absolute;
    display: block;
    top: 0;
    bottom: 0;
    right: 0;
    width: 1px;
    -webkit-transform: translateZ(0px); }
  .sidebar .nav-heading {
    padding: 12px 15px;
    color: #55657F;
    font-size: 13px;
    letter-spacing: .035em;
    pointer-events: none;
    cursor: default; }

.sidebar-nav {
  position: relative;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 1rem;
  padding-left: 0;
  list-style: none; }
  .sidebar-nav > .nav-heading:first-child {
    padding-top: 20px; }
  .sidebar-nav > li {
    display: block;
    border-left: 3px solid transparent;
    transition: border-left-color 0.4s ease; }
    @media (prefers-reduced-motion: reduce) {
      .sidebar-nav > li {
        transition: none; } }
    .sidebar-nav > li > a,
    .sidebar-nav > li > .nav-item {
      position: relative;
      display: block;
      padding: 0.5rem 0.5rem;
      color: #26314A;
      font-weight: 600;
      font-size: 16px;
      cursor: pointer;
      border-bottom: 1px solid #ECF3FF;
      margin-right: 1rem;
      margin-left: 1rem; }
      .sidebar-nav > li > a:focus, .sidebar-nav > li > a:hover,
      .sidebar-nav > li > .nav-item:focus,
      .sidebar-nav > li > .nav-item:hover {
        text-decoration: none;
        outline: none;
        color: #F95C1E; }
      .sidebar-nav > li > a:focus,
      .sidebar-nav > li > .nav-item:focus {
        color: #26314A; }
      .sidebar-nav > li > a > .nav-item-icon,
      .sidebar-nav > li > .nav-item > .nav-item-icon {
        width: 1.8em;
        display: inline-block;
        font-style: normal;
        line-height: 1;
        -webkit-font-smoothing: antialiased;
        color: #F95C1E; }
    .sidebar-nav > li div.nav-item {
      cursor: default; }
      .sidebar-nav > li div.nav-item:hover {
        color: #26314A; }
  .sidebar-nav .badge {
    display: block; }

@media only screen and (min-width: 1025px) {
  .sidebar:not(.show-scrollbar) {
    overflow-y: auto; } }

nav.sidebar::-webkit-scrollbar-thumb {
  background-color: #F95C1E; }

nav.sidebar::-webkit-scrollbar {
  width: 0.5em; }

.sidebar-subnav {
  border-radius: 0.4rem; }
  .sidebar-subnav > .sidebar-subnav-header {
    color: #26314A;
    display: none;
    padding: 10px 20px;
    font-weight: bold; }
  .sidebar-subnav > li {
    border-left: 0 !important; }
    .sidebar-subnav > li > a,
    .sidebar-subnav > li > .nav-item {
      display: block;
      position: relative;
      padding: 10px 10px;
      padding-left: 14px;
      font-weight: normal;
      background-color: transparent !important;
      color: #26314A;
      border-bottom: 0; }
      .sidebar-subnav > li > a::before,
      .sidebar-subnav > li > .nav-item::before {
        content: '';
        display: inline-block;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #F95C1E;
        position: relative;
        top: -3px;
        margin-right: 1.1rem; }
      .sidebar-subnav > li > a:focus, .sidebar-subnav > li > a:hover,
      .sidebar-subnav > li > .nav-item:focus,
      .sidebar-subnav > li > .nav-item:hover {
        color: #F95C1E; }
      .sidebar-subnav > li > a > em,
      .sidebar-subnav > li > .nav-item > em {
        display: inline-block;
        width: 1.8em;
        margin: 0 0 0 -2em; }
    .sidebar-subnav > li.active > a,
    .sidebar-subnav > li.active > .nav-item {
      color: #F95C1E; }
      .sidebar-subnav > li.active > a::before,
      .sidebar-subnav > li.active > .nav-item::before {
        background-color: #F95C1E; }
      .sidebar-subnav > li.active > a:after,
      .sidebar-subnav > li.active > .nav-item:after {
        border-color: #E54607;
        background-color: #E54607; }
    .sidebar-subnav > li .sidebar-nav > li {
      padding-left: 5px; }
  .sidebar-subnav.nav-floating {
    border: 1px solid rgba(0, 0, 0, 0.15);
    margin-left: -1px; }
    .sidebar-subnav.nav-floating,
    .sidebar-subnav.nav-floating .collapse, .sidebar-subnav.nav-floating .sidebar-subnav {
      height: auto !important;
      display: block !important;
      visibility: visible !important; }
      .sidebar-subnav.nav-floating.opening,
      .sidebar-subnav.nav-floating .collapse.opening, .sidebar-subnav.nav-floating .sidebar-subnav.opening {
        animation: none !important; }
    .sidebar-subnav.nav-floating > .sidebar-subnav-header {
      display: block; }
    .sidebar-subnav.nav-floating li > a,
    .sidebar-subnav.nav-floating li > .nav-item {
      padding-left: 20px; }
      .sidebar-subnav.nav-floating li > a em,
      .sidebar-subnav.nav-floating li > .nav-item em {
        margin-left: 0; }

@media (min-width: 768px) {
  .sidebar > .sidebar-nav .badge {
    margin: 4px 0 0 0; } }

.aside-collapsed .sidebar,
.aside-collapsed-text .sidebar {
  overflow-x: hidden; }
  .aside-collapsed .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed .sidebar > .sidebar-nav > li > a > span,
  .aside-collapsed-text .sidebar > .sidebar-nav .sidebar-nav,
  .aside-collapsed-text .sidebar > .sidebar-nav > .nav-heading,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
    display: none !important; }
  .aside-collapsed .sidebar > .sidebar-nav > li,
  .aside-collapsed-text .sidebar > .sidebar-nav > li {
    width: 69px; }
    .aside-collapsed .sidebar > .sidebar-nav > li > a,
    .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a,
    .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item {
      text-indent: -3px;
      padding: 20px 0;
      text-align: center; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > em {
        font-size: 1.6em;
        width: auto; }
      .aside-collapsed .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus,
      .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus {
        background-color: transparent; }
        .aside-collapsed .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed .sidebar > .sidebar-nav > li > .nav-item:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > a:focus > em,
        .aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item:focus > em {
          color: inherit; }
  .aside-collapsed .sidebar .sidebar-nav .badge,
  .aside-collapsed-text .sidebar .sidebar-nav .badge {
    position: absolute;
    top: 10px;
    right: 5px;
    text-indent: 0; }

.aside-collapsed-text .sidebar > .sidebar-nav > li > .nav-item > span,
.aside-collapsed-text .sidebar > .sidebar-nav > li > a > span {
  font-size: 12px; }

.aside-collapsed-text .sidebar > .sidebar-nav > li {
  width: 88px; }
  .aside-collapsed-text .sidebar > .sidebar-nav > li > a {
    padding: 14px 0; }
    .aside-collapsed-text .sidebar > .sidebar-nav > li > a > em {
      font-size: 1.4em; }

/* ========================================================================
     Component: offsidebar
 ========================================================================== */
.offsidebar {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  width: 290px;
  margin-top: 65px;
  padding-top: 20px;
  box-shadow: 0 0 5px rgba(85, 101, 127, 0.2);
  background-color: #edf2f9;
  color: #26314A;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
  z-index: -1; }
  .offsidebar > .list-group {
    min-height: 100%;
    overflow: hidden;
    -webkit-transform: translateZ(0px); }
  .offsidebar .progress {
    border: 0; }
  .offsidebar .tab-content {
    padding: 0;
    border: 0; }
  .offsidebar .nav-tabs {
    border-bottom: 0; }
    .offsidebar .nav-tabs > .nav-item > .nav-link {
      background-color: transparent;
      border: 0;
      border-right: 1px solid rgba(0, 0, 0, 0.05);
      border-radius: 0;
      color: #909FA7; }
      .offsidebar .nav-tabs > .nav-item > .nav-link.active {
        color: #F95C1E;
        background-color: transparent; }
  @media (min-width: 768px) {
    .offsidebar {
      margin-top: 65px; } }

.offsidebar-open .offsidebar {
  overflow-y: auto;
  z-index: 116; }

@media (min-width: 768px) {
  .offsidebar-open {
    overflow-y: auto; } }

.offsidebar {
  right: -290px; }

.no-csstransforms3d .offsidebar-open .offsidebar {
  right: 0; }

/* Transformation ready devices*/
.csstransforms3d .offsidebar {
  right: 0;
  transform: translate3d(290px, 0, 0);
  transition: transform .3s ease, z-index 0s linear .3s; }

.csstransforms3d .offsidebar-open .offsidebar {
  transform: translate3d(0, 0, 0);
  transition: transform .3s ease; }

/* ========================================================================
     Component: user-block
 ========================================================================== */
.has-user-block {
  display: block;
  border: 0 !important; }

.user-block {
  position: relative;
  padding: 1rem 1rem;
  display: flex;
  flex-flow: row nowrap;
  align-items: center; }
  .user-block > .user-block-picture {
    flex: 0 0 auto;
    position: relative;
    width: 60px;
    text-align: center;
    margin-right: 10px; }
    .user-block > .user-block-picture > img {
      max-width: 100%;
      height: auto; }
  .user-block .user-block-info {
    flex: 1 1 auto;
    text-align: left;
    min-width: 0; }
    .user-block .user-block-info .user-block-name, .user-block .user-block-info .user-block-role {
      display: block; }
    .user-block .user-block-info .user-block-name {
      font-size: 17px;
      font-weight: 600;
      line-height: 1.05;
      color: #F95C1E;
      margin-bottom: 2px; }
      .user-block .user-block-info .user-block-name:hover, .user-block .user-block-info .user-block-name:focus {
        color: #E54607;
        text-decoration: none; }
    .user-block .user-block-info .user-block-role {
      font-size: 12px;
      color: #aaa; }

.user-block-status {
  position: relative; }
  .user-block-status > .circle {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 2px solid #fff; }

.aside-collapsed .user-block,
.aside-collapsed-text .user-block {
  padding: 15px 0 14px;
  margin: 0;
  text-align: center; }
  .aside-collapsed .user-block > .user-block-picture, .aside-collapsed-text .user-block > .user-block-picture {
    float: none;
    margin: 0  auto;
    width: 50px; }
    .aside-collapsed .user-block > .user-block-picture > .user-block-status, .aside-collapsed-text .user-block > .user-block-picture > .user-block-status {
      display: block; }
  .aside-collapsed .user-block .user-block-info, .aside-collapsed-text .user-block .user-block-info {
    display: none; }

.setting-color {
  padding: 0 5px; }
  .setting-color > label {
    display: block;
    position: relative;
    margin: 0 10px;
    border-radius: 3px;
    overflow: hidden;
    border: 1px solid rgba(0, 0, 0, 0.1);
    cursor: pointer; }
    .setting-color > label:first-child {
      margin-left: 0; }
    .setting-color > label:last-child {
      margin-right: 0; }
    .setting-color > label > .color {
      display: block;
      height: 18px; }
    .setting-color > label > .split {
      display: block; }
      .setting-color > label > .split::after {
        display: block;
        clear: both;
        content: ""; }
      .setting-color > label > .split > .color {
        display: block;
        height: 37.5px; }
        .setting-color > label > .split > .color:first-child {
          float: left;
          width: 70%; }
        .setting-color > label > .split > .color:last-child {
          float: right;
          width: 30%; }
    .setting-color > label > .icon-check {
      position: absolute;
      display: block;
      left: 50%;
      top: 50%;
      width: 20px;
      height: 20px;
      margin-top: -20px;
      margin-left: -10px;
      text-align: center;
      font-size: 1.33333333em;
      vertical-align: -15%;
      color: #fff;
      opacity: 0; }
    .setting-color > label > input[type="radio"] {
      position: absolute;
      opacity: 0;
      visibility: hidden; }
      .setting-color > label > input[type="radio"]:checked + .icon-check {
        opacity: 1 !important; }

/* ========================================================================
     Component: bootstrap-reset
 ========================================================================== */
*:not(.react-grid-Cell):focus {
  outline: 0 !important; }

a {
  outline: none !important; }
  a.text-muted:hover, a.text-muted:focus {
    color: #748690; }

hr {
  border-top: 1px solid #e3e7ea; }

[data-now] {
  display: inline-block; }

.badge {
  padding: 0.1875rem 0.4375rem;
  font-size: 0.75rem;
  font-weight: 600; }
  .badge.badge-warning {
    color: #fff !important; }
  .badge.badge-secondary {
    background-color: #55657F;
    color: #fff; }

.list-group {
  line-height: 1.3; }
  .list-group .list-group-item {
    padding: 10px;
    color: #555; }
    .list-group .list-group-item.active {
      color: #fff; }
      .list-group .list-group-item.active .badge {
        background-color: #fff;
        color: #F95C1E; }
    .card > .list-group .list-group-item {
      border: 0; }

.list-group + .card-footer {
  border-top: 0; }

.page-header {
  padding-bottom: 0.59375rem;
  margin: 2.625rem 0 1.3125rem;
  border-bottom: 1px solid #e3e7ea; }

.card .card-header {
  border-bottom: 0;
  padding: 0.625rem 0.9375rem;
  background-color: transparent; }
  .card .card-header .card-title {
    margin-bottom: 0;
    font-size: 1rem; }
  .card .card-header h2.card-title {
    font-size: 1.25rem; }
  .card .card-header a {
    text-decoration: none !important; }

.card-body {
  padding: 0.9375rem; }

.card-footer {
  padding: 0.625rem 0.9375rem; }

.card-default {
  border-color: #dce7ed; }
  .card-default .card-header {
    background-color: #fff; }

.well {
  border: 1px solid #dde6e9; }

.jumbotron {
  border: 1px solid #dde6e9;
  background-color: #fff; }
  @media (min-width: 768px) {
    .jumbotron {
      padding: 2rem 1rem; } }

.nav-tabs > .nav-item > .nav-link {
  font-weight: bold;
  color: #26314A;
  background-color: #dce7ed;
  margin: 0;
  border: 1px solid #dde6e9;
  border-radius: 0;
  padding: 10px 20px; }
  .nav-tabs > .nav-item > .nav-link.active {
    background-color: #fff; }
    .nav-tabs > .nav-item > .nav-link.active, .nav-tabs > .nav-item > .nav-link.active:hover, .nav-tabs > .nav-item > .nav-link.active:focus {
      border-bottom-color: #fff; }

.tab-content {
  padding: 10px 20px;
  border-style: solid;
  border-width: 0 1px 1px 1px;
  border-color: #dde6e9; }
  .nav-pills + .tab-content {
    border: 0;
    padding: 0; }
  .p-0 .tab-content {
    padding: 0 !important; }

.btn {
  border-radius: 5px;
  font-size: 1rem;
  font-weight: 400;
  -webkit-appearance: none;
  outline: none !important;
  transition: all 0.1s; }
  @media (prefers-reduced-motion: reduce) {
    .btn {
      transition: none; } }
  .btn.btn-link {
    box-shadow: none;
    border: 0; }
  .input-group .btn {
    font-size: 14px;
    border-color: #dde6e9; }
  .input-group .form-control-sm + .input-group-btn .btn {
    font-size: 0.8125rem;
    padding: 0.3125rem 0.625rem;
    margin-left: -1px;
    border-top-left-radius: 0;
    border-bottom-left-radius: 0; }

.btn-group {
  position: relative;
  top: -0.05em; }

.btn.btn-warning {
  color: #fff; }

.btn.btn-outline-warning:hover, .btn.btn-outline-warning:focus, .btn.btn-outline-warning:active {
  color: #fff; }

.btn-default {
  background-color: #ffffff;
  border-color: #dde6e9;
  color: #26314A; }
  .btn-default:hover, .btn-default:focus {
    color: #26314A;
    background-color: #edf2f9; }

span.btn {
  -webkit-appearance: none !important; }

.form-control {
  font-size: 0.875rem; }
  .form-control:focus {
    border-color: #F95C1E; }

.form-control-sm,
select.form-control-sm {
  font-size: .75rem;
  height: 31px; }

.custom-select {
  -webkit-appearance: none; }

.custom-control-label::before {
  top: 0.19rem;
  border: 1px solid #dde6e9; }

fieldset {
  padding-bottom: 20px;
  border-bottom: 1px dashed #eee;
  margin-bottom: 20px; }
  fieldset.last-child, fieldset:last-child {
    border-bottom: 0; }
  fieldset .form-group {
    margin-bottom: 0; }

.input-group-text {
  font-size: .875rem; }

@media (max-width: 767.98px) {
  input[type="text"],
  input[type="email"],
  input[type="search"],
  input[type="password"] {
    -webkit-appearance: none; } }

.table-responsive {
  overflow-y: hidden; }

.table {
  border-collapse: separate;
  border-spacing: 0; }
  .table th {
    font-weight: 600; }
  .table > thead > tr > td,
  .table > thead > tr > th {
    background-color: #ECF3FF;
    color: #26314A;
    border-color: #55657F;
    border-top: 0;
    border-bottom: 3px solid #ffffff;
    border-left: 0;
    border-right: 0;
    position: relative; }
    .table > thead > tr > td:first-child,
    .table > thead > tr > th:first-child {
      border-top-left-radius: 0.4rem;
      border-bottom-left-radius: 0.45em; }
    .table > thead > tr > td:last-child,
    .table > thead > tr > th:last-child {
      border-top-right-radius: 0.4rem;
      border-bottom-right-radius: 0.45em; }
  .table > thead > tr > th {
    font-weight: 600; }
  .table > tbody > tr > td,
  .table > tbody > tr > th {
    border-left: 0;
    border-top: 0; }
  .table > tbody + tbody {
    border-bottom-width: 1px; }
  .table .form-control {
    max-width: 100%; }

.table-bordered {
  border-left: 0;
  border-right: 0;
  border-top: 0;
  border-bottom: 0; }
  .table-bordered th, .table-bordered td {
    border-color: #dce7ed; }
  .table-bordered td:first-child,
  .table-bordered th:first-child {
    border-left: 0; }
  .table-bordered td:last-child,
  .table-bordered th:last-child {
    border-right: 0; }
  .table-bordered tbody tr:last-child td, .table-bordered tbody tr:last-child th {
    border-bottom: 0; }

table.table-bordered.dataTable > tbody td, table.table-bordered.dataTable > tbody th {
  border-bottom: 1px solid #dce7ed !important; }

tr.table-danger > td:first-child {
  border-left: 4px solid #f05050; }

tr.table-danger > td,
tr.table-danger > th {
  background-color: #fef4f4;
  border-color: rgba(240, 80, 80, 0.1); }

tr.table-warning > td:first-child {
  border-left: 4px solid #FF7F44; }

tr.table-warning > td,
tr.table-warning > th {
  background-color: #fff9f7;
  border-color: rgba(255, 127, 68, 0.1); }

tr.table-success > td:first-child {
  border-left: 4px solid #27c24c; }

tr.table-success > td,
tr.table-success > th {
  background-color: #e4fae9;
  border-color: rgba(39, 194, 76, 0.1); }

tr.table-info > td:first-child {
  border-left: 4px solid #23b7e5; }

tr.table-info > td,
tr.table-info > th {
  background-color: #feffff;
  border-color: rgba(35, 183, 229, 0.1); }

.progress {
  border-radius: 0.25rem;
  border: 1px solid #e9ecef;
  background-color: #fff; }

.popover {
  box-shadow: 0 0 0 #000;
  border-color: #eee;
  border-bottom: 2px solid #e3e7ea;
  border-radius: 0.25rem; }
  .popover .popover-title {
    border: 0; }

.nav.nav-pills .active > a {
  background-color: #F95C1E; }

.dropdown-menu {
  border-radius: 0.25rem;
  font-size: 14px;
  padding: 0;
  box-shadow: rgba(0, 0, 0, 0.176) 0px 6px 12px;
  border-color: #e1e1e1; }
  .dropdown-menu .dropdown-item {
    line-height: 1.52857143;
    padding: 0.1875rem 1.25rem; }

.dropdown-header {
  color: #a1a2a3; }

.navbar-top .navbar-nav > .active > a {
  color: #999; }
  .navbar-top .navbar-nav > .active > a:hover, .navbar-top .navbar-nav > .active > a:focus {
    color: #d1d2d3; }

.navbar-default .navbar-nav .open .dropdown-menu > li > a {
  color: #666; }
  .navbar-default .navbar-nav .open .dropdown-menu > li > a:hover, .navbar-default .navbar-nav .open .dropdown-menu > li > a:focus {
    color: #222; }

.carousel .carousel-indicators {
  bottom: 0; }

.carousel .carousel-control.left, .carousel .carousel-control.right {
  background-image: none; }

.carousel .carousel-control em {
  position: absolute;
  top: 50%;
  left: 50%;
  z-index: 5;
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-top: -10px;
  margin-left: -10px; }

.alert-brand {
  color: #fff;
  background-color: #FF1A6B;
  border-color: #FF1A6B; }
  .alert-brand hr {
    border-top-color: #ff015b; }
  .alert-brand .alert-link {
    color: #e6e6e6; }

.alert-primary {
  color: #fff;
  background-color: #F95C1E;
  border-color: #F95C1E; }
  .alert-primary hr {
    border-top-color: #f74b07; }
  .alert-primary .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #fff;
  background-color: #FF8C00;
  border-color: #FF8C00; }
  .alert-secondary hr {
    border-top-color: #e67e00; }
  .alert-secondary .alert-link {
    color: #e6e6e6; }

.alert-default {
  color: #fff;
  background-color: #dde6e9;
  border-color: #dde6e9; }
  .alert-default hr {
    border-top-color: #cedbdf; }
  .alert-default .alert-link {
    color: #e6e6e6; }

.alert-subsidiary {
  color: #fff;
  background-color: #55657F;
  border-color: #55657F; }
  .alert-subsidiary hr {
    border-top-color: #4b5970; }
  .alert-subsidiary .alert-link {
    color: #e6e6e6; }

.alert-success {
  color: #fff;
  background-color: #27c24c;
  border-color: #27c24c; }
  .alert-success hr {
    border-top-color: #23ad44; }
  .alert-success .alert-link {
    color: #e6e6e6; }

.alert-info {
  color: #fff;
  background-color: #23b7e5;
  border-color: #23b7e5; }
  .alert-info hr {
    border-top-color: #19a9d5; }
  .alert-info .alert-link {
    color: #e6e6e6; }

.alert-warning {
  color: #fff;
  background-color: #FF7F44;
  border-color: #FF7F44; }
  .alert-warning hr {
    border-top-color: #ff6e2b; }
  .alert-warning .alert-link {
    color: #e6e6e6; }

.alert-danger {
  color: #fff;
  background-color: #f05050;
  border-color: #f05050; }
  .alert-danger hr {
    border-top-color: #ee3939; }
  .alert-danger .alert-link {
    color: #e6e6e6; }

.alert-secondary {
  color: #26314A;
  border-color: #e3e7ea; }

.topnavbar,
.navbar,
.navbar .dropdown-menu {
  filter: none !important; }

/* ========================================================================
     Component: bootstrap-custom
 ========================================================================== */
.container-sm {
  max-width: 540px;
  width: auto; }

.container-md {
  max-width: 720px;
  width: auto; }

.container-lg {
  max-width: 960px;
  width: auto; }

.container-xl {
  max-width: 1140px;
  width: auto; }

.container-xxl {
  max-width: 1420px;
  width: auto; }

@media (min-width: 0) {
  .card-deck .card {
    flex: 0 0 calc(100% - 20px);
    margin-bottom: 20px; } }

@media (min-width: 576px) {
  .card-deck .card {
    flex: 0 0 calc(50% - 20px);
    margin-bottom: 20px; } }

@media (min-width: 768px) {
  .card-deck .card {
    flex: 0 0 calc(33.33333% - 20px);
    margin-bottom: 20px; } }

@media (min-width: 992px) {
  .card-deck .card {
    flex: 0 0 calc(33.33333% - 20px);
    margin-bottom: 20px; } }

@media (min-width: 1200px) {
  .card-deck .card {
    flex: 0 0 calc(33.33333% - 20px);
    margin-bottom: 20px; } }

.row-flush {
  margin: 0; }
  .row-flush > .col,
  .row-flush > [class*="col-"] {
    padding-left: 0;
    padding-right: 0; }

.breadcrumb {
  font-weight: normal;
  border-radius: 0;
  color: #909FA7;
  padding: 10px 20px; }

.content-heading .breadcrumb {
  font-size: 0.8125rem;
  margin-bottom: 0; }

.content-heading + .breadcrumb {
  margin: -25px -25px 20px -20px;
  background-color: #edf2f9;
  border-top: 1px solid #cfdbe2;
  border-bottom: 1px solid #cfdbe2; }

.progress-sm {
  height: 15px; }

.progress-xs {
  height: 8px; }

.badge-inverse {
  color: #fff;
  background-color: #131e26; }
  a.badge-inverse:hover, a.badge-inverse:focus {
    color: #fff;
    background-color: #020304; }
  a.badge-inverse:focus, a.badge-inverse.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }

.badge-green {
  color: #fff;
  background-color: #37bc9b; }
  a.badge-green:hover, a.badge-green:focus {
    color: #fff;
    background-color: #2b957a; }
  a.badge-green:focus, a.badge-green.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }

.badge-pink {
  color: #fff;
  background-color: #f532e5; }
  a.badge-pink:hover, a.badge-pink:focus {
    color: #fff;
    background-color: #e90bd6; }
  a.badge-pink:focus, a.badge-pink.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }

.badge-purple {
  color: #fff;
  background-color: #7266ba; }
  a.badge-purple:hover, a.badge-purple:focus {
    color: #fff;
    background-color: #564aa3; }
  a.badge-purple:focus, a.badge-purple.focus {
    outline: 0;
    box-shadow: 0 0 0 0.2rem rgba(114, 102, 186, 0.5); }

.alert-purple {
  color: #fff;
  background-color: #7266ba;
  border-color: #7266ba; }
  .alert-purple hr {
    border-top-color: #6254b2; }
  .alert-purple .alert-link {
    color: #e6e6e6; }

.alert-green {
  color: #fff;
  background-color: #37bc9b;
  border-color: #37bc9b; }
  .alert-green hr {
    border-top-color: #31a88b; }
  .alert-green .alert-link {
    color: #e6e6e6; }

.alert-pink {
  color: #fff;
  background-color: #f532e5;
  border-color: #f532e5; }
  .alert-pink hr {
    border-top-color: #f41ae2; }
  .alert-pink .alert-link {
    color: #e6e6e6; }

.alert-inverse {
  color: #fff;
  background-color: #131e26;
  border-color: #131e26; }
  .alert-inverse hr {
    border-top-color: #0b1115; }
  .alert-inverse .alert-link {
    color: #e6e6e6; }

.form-control-rounded {
  border-radius: 100px; }

.d-flex-centered {
  min-height: 100vh;
  height: auto;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  padding: 30px 15px; }
  @media (min-width: 768px) {
    .d-flex-centered {
      align-items: center; }
      .d-flex-centered > * {
        margin-top: -10%; } }
  @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .d-flex-centered {
      /* IE10+ CSS styles go here */
      padding: 0 !important; }
      .d-flex-centered:after {
        content: '';
        min-height: inherit;
        font-size: 0; } }

/* ========================================================================
     Component: button-extra
 ========================================================================== */
.btn-inverse {
  color: #fff;
  background-color: #131e26;
  border-color: #131e26; }
  .btn-inverse:hover {
    color: #fff;
    background-color: #060a0d;
    border-color: #020304; }
  .btn-inverse:focus, .btn-inverse.focus {
    box-shadow: 0 0 0 0.2rem rgba(54, 64, 71, 0.5); }
  .btn-inverse.disabled, .btn-inverse:disabled {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
  .btn-inverse:not(:disabled):not(.disabled):active, .btn-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-inverse.dropdown-toggle {
    color: #fff;
    background-color: #020304;
    border-color: black; }
    .btn-inverse:not(:disabled):not(.disabled):active:focus, .btn-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(54, 64, 71, 0.5); }

.btn-green {
  color: #fff;
  background-color: #37bc9b;
  border-color: #37bc9b; }
  .btn-green:hover {
    color: #fff;
    background-color: #2e9e83;
    border-color: #2b957a; }
  .btn-green:focus, .btn-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(85, 198, 170, 0.5); }
  .btn-green.disabled, .btn-green:disabled {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
  .btn-green:not(:disabled):not(.disabled):active, .btn-green:not(:disabled):not(.disabled).active,
  .show > .btn-green.dropdown-toggle {
    color: #fff;
    background-color: #2b957a;
    border-color: #298b72; }
    .btn-green:not(:disabled):not(.disabled):active:focus, .btn-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(85, 198, 170, 0.5); }

.btn-purple {
  color: #fff;
  background-color: #7266ba;
  border-color: #7266ba; }
  .btn-purple:hover {
    color: #fff;
    background-color: #5b4eac;
    border-color: #564aa3; }
  .btn-purple:focus, .btn-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(135, 125, 196, 0.5); }
  .btn-purple.disabled, .btn-purple:disabled {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
  .btn-purple:not(:disabled):not(.disabled):active, .btn-purple:not(:disabled):not(.disabled).active,
  .show > .btn-purple.dropdown-toggle {
    color: #fff;
    background-color: #564aa3;
    border-color: #52469b; }
    .btn-purple:not(:disabled):not(.disabled):active:focus, .btn-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(135, 125, 196, 0.5); }

.btn-pink {
  color: #fff;
  background-color: #f532e5;
  border-color: #f532e5; }
  .btn-pink:hover {
    color: #fff;
    background-color: #f30ee0;
    border-color: #e90bd6; }
  .btn-pink:focus, .btn-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(247, 81, 233, 0.5); }
  .btn-pink.disabled, .btn-pink:disabled {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
  .btn-pink:not(:disabled):not(.disabled):active, .btn-pink:not(:disabled):not(.disabled).active,
  .show > .btn-pink.dropdown-toggle {
    color: #fff;
    background-color: #e90bd6;
    border-color: #dc0bcb; }
    .btn-pink:not(:disabled):not(.disabled):active:focus, .btn-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(247, 81, 233, 0.5); }

.btn-sm + .btn-sm {
  margin-left: 0.4em; }

.btn-flat {
  border-bottom-width: 1px;
  border-radius: 0;
  box-shadow: 0 0 0 #000; }

.btn-xs {
  padding: 0.0625rem 0.3125rem;
  font-size: 1;
  line-height: 1.5; }

.btn-xl {
  padding: 20px 16px;
  font-size: 18px; }

.btn-square {
  border-radius: 0; }

.btn-pill-left, .btn-oval {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
  padding-left: 18px; }

.btn-pill-right, .btn-oval {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding-right: 18px; }

.btn-labeled {
  padding-top: 0;
  padding-bottom: 0; }

.btn-label {
  position: relative;
  background: transparent;
  display: inline-block;
  font-size: 0.85em;
  padding: 0.5rem 0.5rem;
  left: -0.5rem;
  border-radius: 0.4rem 0 0 0.4rem; }
  .btn-label.btn-label-right {
    left: auto;
    right: -0.5rem;
    border-radius: 0 0.4rem 0.4rem 0; }

.btn-lg .btn-label {
  padding: 0.625rem 0.625rem;
  left: -0.625rem;
  border-radius: 0.55rem 0 0 0.55rem; }
  .btn-lg .btn-label.btn-label-right {
    left: auto;
    right: -0.625rem;
    border-radius: 0 0.55rem 0.55rem 0; }

.btn-sm .btn-label {
  padding: 0.3125rem 0.3125rem;
  left: -0.3125rem;
  border-radius: 0.25rem 0 0 0.25rem; }
  .btn-sm .btn-label.btn-label-right {
    left: auto;
    right: -0.3125rem;
    border-radius: 0 0.25rem 0.25rem 0; }

.btn-xs .btn-label {
  padding: 1px 5px;
  left: -5px;
  border-radius: 0.25rem 0 0 0.25rem; }
  .btn-xs .btn-label.btn-label-right {
    left: auto;
    right: -5px;
    border-radius: 0 0.25rem 0.25rem 0; }

.btn-fw {
  min-width: 80px; }
  .btn-fw.btn-sm {
    min-width: 40px; }
  .btn-fw.btn-md {
    min-width: 60px; }
  .btn-fw.btn-lg {
    min-width: 140px; }

.btn-circle {
  width: 35px;
  height: 35px;
  text-align: center;
  font-size: 12px;
  line-height: 35px;
  border-radius: 500px;
  padding: 0;
  border: 0; }
  .btn-circle > * {
    line-height: inherit !important; }
  .btn-circle.btn-lg {
    width: 50px;
    height: 50px;
    font-size: 18px;
    line-height: 50px; }

.btn-outline-inverse {
  color: #131e26;
  border-color: #131e26; }
  .btn-outline-inverse:hover {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
  .btn-outline-inverse:focus, .btn-outline-inverse.focus {
    box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }
  .btn-outline-inverse.disabled, .btn-outline-inverse:disabled {
    color: #131e26;
    background-color: transparent; }
  .btn-outline-inverse:not(:disabled):not(.disabled):active, .btn-outline-inverse:not(:disabled):not(.disabled).active,
  .show > .btn-outline-inverse.dropdown-toggle {
    color: #fff;
    background-color: #131e26;
    border-color: #131e26; }
    .btn-outline-inverse:not(:disabled):not(.disabled):active:focus, .btn-outline-inverse:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-inverse.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(19, 30, 38, 0.5); }

.btn-outline-green {
  color: #37bc9b;
  border-color: #37bc9b; }
  .btn-outline-green:hover {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
  .btn-outline-green:focus, .btn-outline-green.focus {
    box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }
  .btn-outline-green.disabled, .btn-outline-green:disabled {
    color: #37bc9b;
    background-color: transparent; }
  .btn-outline-green:not(:disabled):not(.disabled):active, .btn-outline-green:not(:disabled):not(.disabled).active,
  .show > .btn-outline-green.dropdown-toggle {
    color: #fff;
    background-color: #37bc9b;
    border-color: #37bc9b; }
    .btn-outline-green:not(:disabled):not(.disabled):active:focus, .btn-outline-green:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-green.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(55, 188, 155, 0.5); }

.btn-outline-purple {
  color: #7266ba;
  border-color: #7266ba; }
  .btn-outline-purple:hover {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
  .btn-outline-purple:focus, .btn-outline-purple.focus {
    box-shadow: 0 0 0 0.2rem rgba(114, 102, 186, 0.5); }
  .btn-outline-purple.disabled, .btn-outline-purple:disabled {
    color: #7266ba;
    background-color: transparent; }
  .btn-outline-purple:not(:disabled):not(.disabled):active, .btn-outline-purple:not(:disabled):not(.disabled).active,
  .show > .btn-outline-purple.dropdown-toggle {
    color: #fff;
    background-color: #7266ba;
    border-color: #7266ba; }
    .btn-outline-purple:not(:disabled):not(.disabled):active:focus, .btn-outline-purple:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-purple.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(114, 102, 186, 0.5); }

.btn-outline-pink {
  color: #f532e5;
  border-color: #f532e5; }
  .btn-outline-pink:hover {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
  .btn-outline-pink:focus, .btn-outline-pink.focus {
    box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }
  .btn-outline-pink.disabled, .btn-outline-pink:disabled {
    color: #f532e5;
    background-color: transparent; }
  .btn-outline-pink:not(:disabled):not(.disabled):active, .btn-outline-pink:not(:disabled):not(.disabled).active,
  .show > .btn-outline-pink.dropdown-toggle {
    color: #fff;
    background-color: #f532e5;
    border-color: #f532e5; }
    .btn-outline-pink:not(:disabled):not(.disabled):active:focus, .btn-outline-pink:not(:disabled):not(.disabled).active:focus,
    .show > .btn-outline-pink.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(245, 50, 229, 0.5); }

/* ========================================================================
     Component: dropdown-extras
 ========================================================================== */
.dropdown-list .dropdown-item {
  padding: 0; }

.dropdown-list > .dropdown-menu {
  padding: 0;
  min-width: 220px; }

.dropdown-list .list-group {
  margin: 0; }

.dropdown-list .list-group-item {
  border-radius: 0;
  border-left: 0;
  border-right: 0; }
  .dropdown-list .list-group-item:first-child {
    border-top: 0; }
  .dropdown-list .list-group-item:last-child {
    border-bottom: 0; }

.dropdown > a {
  position: relative; }
  .dropdown > a > .badge {
    position: absolute;
    top: 10px;
    right: 0;
    padding: 2px 5px; }

.badge-lg {
  font-size: 1em;
  padding: 0.3em 0.5em; }

.dropdown-menu-right-forced {
  right: 0 !important;
  left: auto !important; }

.dropdown-toggle-nocaret:after {
  display: none; }

.page-item + .page-item {
  margin-left: 3px; }

.page-link {
  border-width: 0;
  border-radius: 0.25rem;
  font-weight: 600; }
  .page-link:hover, .page-link:focus {
    background-color: #edf2f9; }
  .page-item.active .page-link {
    box-shadow: 0 2px 3px rgba(249, 92, 30, 0.45); }
    .page-item.active .page-link:hover, .page-item.active .page-link:focus {
      background-color: #E54607; }

/* ========================================================================
     Component: typo
 ========================================================================== */
body {
  font-family: "Source Sans Pro", sans-serif;
  color: #26314A;
  font-size: 1rem; }

h1, h2, h3, h4 {
  font-weight: bold; }

h1, h2, h3, h4, h5, h6,
.h1, .h2, .h3, .h4, .h5, .h6 {
  margin-bottom: 0.65625rem; }

h1, .h1 {
  font-size: 2.25rem; }

h2, .h2 {
  font-size: 1.875rem; }

h3, .h3 {
  font-size: 1.5rem; }

h4, .h4 {
  font-size: 1.125rem; }

h5, .h5 {
  font-size: .875rem; }

h6, .h6 {
  font-size: 1rem; }

p {
  margin-bottom: .65rem; }

.mb-mails tr {
  cursor: pointer; }
  .mb-mails tr td {
    border-bottom: 1px solid #dde6e9; }
  .mb-mails tr.selected {
    background-color: #edf2f9; }

.mailbox-message-conversation-item {
  margin-bottom: 20px; }
  .mailbox-message-conversation-item div {
    border-left: 1px solid #e3e7ea;
    padding-left: 15px; }

.profile-footer {
  background-color: #ffffff;
  border-top: 2px solid #edf2f9;
  z-index: 1;
  padding: 25px; }

.profile-sidebar-block {
  padding-left: 15px;
  padding-right: 15px;
  margin-bottom: 20px; }

.category-container .category-item {
  float: left;
  margin-right: 10px;
  margin-bottom: 10px;
  text-align: center;
  border-radius: 10px;
  padding: 5px 10px;
  cursor: pointer; }

.category-container .categories-group {
  padding-left: 15px;
  padding-right: 15px; }
  .category-container .categories-group-title {
    width: 100%; }

.heading-end .category-item-selected {
  margin-right: 10px;
  text-align: center;
  border-radius: 10px;
  padding: 6px 10px; }

#copy-week-to-week label {
  width: 40%; }

li.sortable-placeholder {
  list-style: none; }

.appointment-type h4 {
  margin-bottom: 15px; }

.appointment-type-block {
  width: 100%;
  padding: 10px 15px;
  margin-bottom: 15px;
  background-color: #e2edff;
  border-radius: 5px; }
  .appointment-type-block__arrow {
    float: left;
    width: 2%; }
    @media (max-width: 991.98px) {
      .appointment-type-block__arrow {
        margin-right: 10px; } }
    @media (min-width: 992px) {
      .appointment-type-block__arrow {
        margin-right: 10px; } }
  .appointment-type-block__header .cta {
    float: right;
    color: white; }

.subcategory-badge, .subcategory-badge-large {
  margin-left: 5px;
  text-align: center;
  border-radius: 10px;
  padding: 2px 10px;
  font-size: .9rem; }
  .subcategory-badge-large {
    margin-left: 0;
    padding: 4px 10px;
    font-size: 1.1rem; }

/* ========================================================================
     Component: Page loader
 ========================================================================== */
.page-loader {
  position: fixed;
  bottom: 0;
  right: 0;
  top: 0;
  left: 0;
  padding-top: 30px;
  text-align: center; }

@media (min-width: 768px) {
  .section-container .page-loader {
    top: 65px;
    left: 275px; }
  .aside-collapsed .section-container .page-loader {
    left: 70px; }
  .aside-collapsed-text .section-container .page-loader {
    left: 90px; } }

/* ========================================================================
     Component: placeholder
 ========================================================================== */
.box-placeholder {
  margin-bottom: 15px;
  padding: 20px;
  border: 1px dashed #ddd;
  background: #fafafa;
  color: #444; }

.box-placeholder > :last-child {
  margin-bottom: 0; }

.box-placeholder-lg {
  padding-top: 80px;
  padding-bottom: 80px; }

/* ========================================================================
     Component: cards
 ========================================================================== */
.card {
  margin-bottom: 1.25rem; }

.card .table {
  margin-bottom: 0; }
  .card .table > thead > tr > th {
    border-top: 0; }

.card.card-transparent {
  border: 0;
  background-color: transparent;
  box-shadow: 0 0 0 #000; }
  .card.card-transparent .card-header,
  .card.card-transparent .card-body {
    background-color: transparent;
    padding-left: 0;
    padding-right: 0; }

.card-flat {
  margin: 0 !important;
  border: 0; }

.card-columns-2 {
  column-count: 1; }
  @media (min-width: 768px) {
    .card-columns-2 {
      column-count: 2; } }

.card-tool {
  display: inline-block;
  color: #fff;
  text-align: center; }
  .card-tool:hover {
    cursor: pointer; }
  .card-tool > em + em {
    margin-left: 12px; }

.card-default .card-header > .card-tool {
  color: #c1c2c3; }

.card-header > .badge.float-right {
  margin-top: 3px; }
  .card-header > .badge.float-right + .badge.float-right {
    margin-right: 10px; }

.card-header:first-child:not(:last-child) {
  padding-bottom: 0; }

.card-header.card-header-primary {
  background-color: #F95C1E;
  color: #ffffff; }

.card-header .card-title {
  font-weight: 600; }

.card-footer {
  box-shadow: inset 0 2px 2px rgba(0, 0, 0, 0.05); }
  .card-footer .pagination {
    margin: 0; }
  .card-footer .radial-bar {
    margin-bottom: 0; }
  .card-footer p {
    margin-bottom: 0; }

.pricebadge {
  font-size: 1.25rem;
  color: #F95C1E;
  font-weight: 600;
  vertical-align: middle;
  padding-left: 0.2rem;
  padding-right: 0.2rem; }
  .pricebadge:not(:last-child) {
    margin-right: 0.4rem; }

/* ========================================================================
     Component: circles
 ========================================================================== */
.circle {
  display: inline-block;
  width: 7px;
  height: 7px;
  border-radius: 500px;
  margin: 0 .5em;
  background-color: #ddd;
  vertical-align: baseline;
  border: 2px solid transparent; }
  .circle.text-left {
    margin-left: 0; }
  .circle.text-right {
    margin-right: 0; }

.circle-sm {
  width: 5px;
  height: 5px; }

.circle-lg {
  width: 11px;
  height: 11px; }

.circle-xl {
  width: 18px;
  height: 18px; }

/* ========================================================================
     Component: half-float
 ========================================================================== */
.half-float {
  position: relative;
  margin-bottom: 69px; }
  .half-float .half-float-bottom,
  .half-float .half-float-top {
    position: absolute;
    left: 50%;
    bottom: -64px;
    width: 128px;
    height: 128px;
    margin-left: -64px;
    z-index: 2; }
  .half-float .half-float-top {
    bottom: auto;
    top: -64px; }

/* ========================================================================
     Component: slim-scroll
 ========================================================================== */
[data-scrollable] {
  display: block; }

.slimScrollBar {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.35) !important;
  border: 0 !important;
  border-radius: 1px !important; }

.slimScrollRail {
  opacity: 1 !important;
  background-color: rgba(0, 0, 0, 0.15) !important;
  border: 0 !important;
  border-radius: 0 !important;
  bottom: 0; }

/* ========================================================================
     Component: inputs
 ========================================================================== */
/**
 * Custom form elements
 *    - Checkbox
 *    - Radios
 *    - Switch
 *    - Note editor
 */
.c-checkbox,
.c-radio {
  margin-right: 4px; }
  .c-checkbox *,
  .c-radio * {
    cursor: pointer; }
  .c-checkbox input,
  .c-radio input {
    opacity: 0;
    position: absolute;
    margin-left: 0 !important; }
  .c-checkbox span,
  .c-radio span {
    position: relative;
    display: inline-block;
    vertical-align: top;
    width: 20px;
    height: 20px;
    border-radius: 0.25rem;
    border: 1px solid #dce7ed;
    background-color: #ffffff;
    color: #ffffff;
    margin-right: 5px;
    text-align: center; }
    .c-checkbox span:before,
    .c-radio span:before {
      margin-left: 1px; }
  .c-checkbox:hover span,
  .c-radio:hover span {
    border-color: #F95C1E; }
  .form-inline .c-checkbox span, .form-inline
  .c-radio span {
    margin-left: 0; }
  .c-checkbox.c-checkbox-rounded span, .c-checkbox.c-radio-rounded span,
  .c-radio.c-checkbox-rounded span,
  .c-radio.c-radio-rounded span {
    border-radius: 500px; }

/* override for radio */
.c-radio span {
  border-radius: 500px; }

/* the icon */
.c-checkbox span:before,
.c-radio span:before {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0;
  text-align: center !important;
  font-size: 12px;
  line-height: 18px;
  vertical-align: middle; }

/* Checked state */
.c-checkbox,
.c-radio {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-checkbox input[type=checkbox]:checked + span:before,
  .c-checkbox input[type=radio]:checked + span:before,
  .c-radio input[type=checkbox]:checked + span:before,
  .c-radio input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-checkbox input[type=checkbox]:checked + span,
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=checkbox]:checked + span,
  .c-radio input[type=radio]:checked + span {
    border-color: #F95C1E;
    background-color: #F95C1E; }
  .c-checkbox input[type=radio]:checked + span,
  .c-radio input[type=radio]:checked + span {
    background-color: #fff; }
    .c-checkbox input[type=radio]:checked + span:before,
    .c-radio input[type=radio]:checked + span:before {
      color: #F95C1E; }
  .c-checkbox input[type=checkbox]:disabled + span,
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=checkbox]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-checkbox input[type=radio]:disabled + span,
  .c-radio input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-checkbox input[type=radio]:disabled + span:before,
    .c-radio input[type=radio]:disabled + span:before {
      color: #ddd; }

.c-radio.c-radio-nofont {
  /* override for radio */
  /* Disable state */
  /* override for radio */ }
  .c-radio.c-radio-nofont span:before {
    content: "";
    width: 10px;
    height: 10px;
    top: 50%;
    left: 50%;
    margin-top: -5px;
    margin-left: -5px;
    border-radius: 500px; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
    color: #fff;
    opacity: 1;
    transition: color .3s ease-out; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    border-color: #F95C1E;
    background-color: #F95C1E; }
  .c-radio.c-radio-nofont input[type=radio]:checked + span {
    background-color: #fff; }
    .c-radio.c-radio-nofont input[type=radio]:checked + span:before {
      background-color: #F95C1E; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    border-color: #ddd !important;
    background-color: #ddd !important; }
  .c-radio.c-radio-nofont input[type=radio]:disabled + span {
    background-color: #fff !important; }
    .c-radio.c-radio-nofont input[type=radio]:disabled + span:before {
      background-color: #ddd; }

.switch .form-control {
  padding-top: 7px;
  margin-bottom: 0; }

.switch * {
  cursor: pointer; }

.switch input {
  opacity: 0;
  position: absolute;
  z-index: -1; }

.switch {
  display: flex;
  align-items: center;
  margin: 0; }
  .switch span {
    position: relative;
    display: inline-block;
    width: 40px;
    height: 20px;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 100px;
    transition: all .5s;
    box-shadow: 1px 2px 3px rgba(0, 0, 0, 0.1) inset; }
  .switch span:after {
    content: "";
    position: absolute;
    background-color: #fff;
    top: 0;
    left: 0;
    height: 18px;
    width: 18px;
    border: 1px solid #ddd;
    border-radius: 400px;
    box-shadow: 1px 1px 3px rgba(0, 0, 0, 0.1);
    -webkit-transition: all .2s; }
  .switch.switch-lg span {
    width: 50px;
    height: 25px; }
  .switch.switch-lg span:after {
    height: 23px;
    width: 23px; }
  .switch.switch-sm span {
    width: 30px;
    height: 15px; }
  .switch.switch-sm span:after {
    height: 13px;
    width: 13px; }

.switch input:checked + span {
  background-color: #F95C1E;
  border-color: #F95C1E;
  transition: all .5s; }

.switch input:checked + span:after {
  left: 50%;
  transition: all .2s; }

.switch input:disabled + span {
  background-color: #f1f1f1;
  cursor: not-allowed; }

.note-editor {
  background-image: -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
  background-image: linear-gradient(#eee 0.1em, transparent 0.1em);
  background-size: 100% 20px;
  background-color: #fff;
  line-height: 20px;
  margin-top: 5px;
  padding: 0;
  padding-bottom: 1px;
  border: none; }
  .note-editor.note-editor-margin {
    background-image: -webkit-linear-gradient(0deg, transparent 49px, #ffe8dd 49px, #ffe8dd 51px, transparent 51px), -webkit-linear-gradient(#eee 0.1em, transparent 0.1em);
    background-image: linear-gradient(90deg, transparent 49px, #ffe8dd 49px, #ffe8dd 51px, transparent 51px), linear-gradient(#eee 0.1em, transparent 0.1em);
    padding-left: 55px; }

.Toastify__toast-body {
  white-space: pre-line; }

.heading {
  display: grid;
  align-items: center;
  margin: -15px;
  margin-bottom: 20px;
  padding: 15px 15px;
  font-weight: normal;
  background-color: #edf2f9;
  min-height: 54px; }
  .heading.heading-nomargin {
    margin-bottom: 0; }
  @media (min-width: 1200px) {
    .heading {
      display: flex; } }

.heading-start {
  margin-bottom: 15px; }
  @media (min-width: 1200px) {
    .heading-start {
      margin-bottom: 0; } }

.heading-end {
  margin-left: auto; }
  .heading-end > * {
    float: left; }

.heading-title {
  line-height: 1.1;
  margin-bottom: 0;
  font-size: 21px;
  color: #55657F;
  font-weight: 600; }

.heading-subtitle {
  font-size: 16px;
  color: #94A0B2;
  font-weight: 500;
  margin-bottom: 0; }

button.react-calendar__tile.react-calendar__month-view__days__day .react-calendar-tooltip {
  position: fixed;
  width: 120px;
  display: none;
  background-color: rgba(38, 49, 74, 0.85);
  color: #ffffff;
  text-align: center;
  border-radius: 0.3em;
  padding: 0.4em;
  z-index: 99;
  margin-top: -60px; }

button.react-calendar__tile.react-calendar__month-view__days__day .react-calendar-availability {
  width: 8px;
  height: 8px;
  position: absolute;
  bottom: 0.4em;
  right: auto;
  left: 50%;
  margin-left: -4px;
  border-radius: 50%;
  border: 0;
  background-color: #ECF3FF; }
  button.react-calendar__tile.react-calendar__month-view__days__day .react-calendar-availability--almost-full {
    background: #ffa277; }
  button.react-calendar__tile.react-calendar__month-view__days__day .react-calendar-availability--full {
    background: #f05050; }

.react-calendar {
  width: 100%;
  background-color: #ffffff;
  color: #26314A;
  line-height: 1.125em;
  padding: 0 1rem 0 1rem;
  margin-bottom: 25px; }
  .react-calendar,
  .react-calendar *,
  .react-calendar *:before,
  .react-calendar *:after {
    -moz-box-sizing: border-box;
    -webkit-box-sizing: border-box;
    box-sizing: border-box; }
  .react-calendar button:enabled:hover {
    cursor: pointer; }
  .react-calendar__navigation {
    height: 32px;
    margin-bottom: 3px;
    color: #26314A; }
    .react-calendar__navigation button {
      min-width: 32px;
      background: none;
      margin: 0;
      border: 0;
      outline: none;
      height: auto; }
      .react-calendar__navigation button:enabled:hover {
        color: #F95C1E; }
    .react-calendar__navigation__label {
      font-size: 15px;
      font-weight: 700;
      color: #26314A;
      position: relative;
      top: 2px; }
    .react-calendar__navigation__arrow {
      font-size: 26px;
      font-weight: 700;
      color: #26314A; }
    .react-calendar__navigation__next2-button, .react-calendar__navigation__prev2-button {
      display: none; }
  .react-calendar__month-view__weekdays {
    text-align: center;
    font-size: 0.9em;
    border-radius: 0.4rem;
    overflow: hidden;
    margin-bottom: 3px; }
    .react-calendar__month-view__weekdays__weekday {
      padding: 0.5em;
      background-color: #ECF3FF; }
      .react-calendar__month-view__weekdays__weekday abbr[title] {
        color: #94A0B2;
        text-decoration: none;
        cursor: default; }
  .react-calendar__month-view__weekNumbers .react-calendar__tile {
    color: #26314A;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 0.75em;
    padding: calc(0.75em / 0.75) calc(0.5em / 0.75); }
  .react-calendar__month-view__days__day--neighboringMonth {
    color: #94A0B2 !important; }
  .react-calendar__year-view .react-calendar__tile, .react-calendar__decade-view .react-calendar__tile, .react-calendar__century-view .react-calendar__tile {
    color: #26314A; }
  .react-calendar__tile {
    max-width: 100%;
    text-align: center;
    padding: 0.8em 0.5em;
    background: none;
    border-radius: 0.4rem;
    border: 1px solid transparent;
    position: relative;
    overflow: auto !important;
    color: #26314A; }
    .react-calendar__tile.react-calendar__month-view__days__day {
      padding: 0.6em 0.5em 0.6em 0.5em; }
    .react-calendar__tile:disabled {
      color: #94A0B2; }
    .react-calendar__tile:enabled:hover, .react-calendar__tile:enabled:focus {
      background-color: #ECF3FF;
      border-color: rgba(236, 243, 255, 0.8); }
    .react-calendar__tile--now {
      background-color: #ECF3FF; }
    .react-calendar__tile--active {
      background-color: #ffffff;
      color: #F95C1E;
      border-color: #F95C1E; }
      .react-calendar__tile--active:enabled:hover, .react-calendar__tile--active:enabled:focus {
        background-color: #ffffff;
        color: #F95C1E;
        box-shadow: none;
        border-color: #E54607; }

.form-autosuggest {
  position: absolute;
  width: 40%;
  z-index: 1; }
  .form-autosuggest ul {
    background-color: #ffffff;
    border: 1px solid #dde6e9;
    padding: 0;
    margin: 0; }
    .form-autosuggest ul li {
      list-style: none;
      height: 30px;
      cursor: pointer;
      padding-left: 25px;
      margin-top: 5px;
      line-height: 2em; }
      .form-autosuggest ul li:hover {
        background-color: #e3e7ea; }

.form .whirl {
  min-height: 100px !important; }
  .form .whirl:before {
    background-color: #e3e7ea; }
  .form .whirl:after {
    border-color: #F95C1E; }

.form-group.form-group-hidden {
  display: none; }

.form-group button {
  margin-left: .5rem; }

.form-inline-image {
  display: flex;
  align-items: center; }
  .form-inline-image input {
    padding-left: 1rem; }

@media (max-width: 991.98px) {
  .form-date-time-picker .date {
    width: 45%;
    max-width: 45%;
    flex: 0 0 45%; }
  .form-date-time-picker .time {
    width: 25%;
    max-width: 25%;
    flex: 0 0 25%; } }

.communication-field {
  border: 1px solid #dde6e9;
  padding: 5px;
  display: table; }

.communication-item {
  margin-right: 15px;
  float: left;
  padding: 5px; }
  .communication-item__title {
    white-space: nowrap; }
  .communication-item span {
    padding: 2px 5px 2px 5px;
    border-radius: 10px; }
    .communication-item span.communication-has-data {
      background-color: #F95C1E;
      color: #ffffff; }
    .communication-item span.communication-has-no-data {
      background-color: #f05050;
      color: #ffffff; }
      .communication-item span.communication-has-no-data i {
        cursor: pointer; }
    .communication-item span i {
      margin-left: 5px; }
  .communication-item > i {
    margin-left: 5px;
    cursor: pointer; }

.react-phone-number-input__icon {
  border: 0 !important;
  height: 1.5rem !important; }

.ck-editor__editable_inline {
  min-height: 250px; }

.rdw-editor-main {
  position: relative;
  z-index: 0; }

.indicator {
  border: 1px solid #f05050;
  padding: 0 .5rem;
  font-weight: normal; }

.ck-dropdown__arrow {
  z-index: 0 !important; }

/* ========================================================================
     Component: form-wizard
 ========================================================================== */
.wizard,
.tabcontrol {
  display: block;
  width: 100%;
  overflow: hidden; }

.wizard a,
.tabcontrol a {
  outline: 0; }

.wizard ul,
.tabcontrol ul {
  list-style: none !important;
  padding: 0;
  margin: 0; }

.wizard ul > li,
.tabcontrol ul > li {
  display: block;
  padding: 0; }

.wizard > .steps .current-info,
.tabcontrol > .steps .current-info {
  position: absolute;
  left: -999em; }

.wizard > .content > .title,
.tabcontrol > .content > .title {
  position: absolute;
  left: -999em; }

.wizard > .steps {
  position: relative;
  display: block;
  width: 100%; }
  .wizard > .steps .number {
    font-size: 1.429em; }
  .wizard > .steps > ul > li {
    width: calc(100% / 4); }

.wizard > .content {
  background: #fff;
  display: block;
  margin: 0.5em;
  min-height: 300px;
  overflow: hidden;
  position: relative;
  width: auto;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px; }
  .wizard > .content > .body {
    float: left;
    position: absolute;
    width: 100%;
    height: 95%;
    padding: 2.5% 0; }
    .wizard > .content > .body ul {
      list-style: disc !important; }
      .wizard > .content > .body ul > li {
        display: list-item; }
    .wizard > .content > .body > iframe {
      border: 0 none;
      width: 100%;
      height: 100%; }
    .wizard > .content > .body input {
      display: block; }
    .wizard > .content > .body input.error {
      background: #fbe3e4;
      border: 1px solid #fbc2c4;
      color: #8a1f11; }
    .wizard > .content > .body label {
      display: inline-block;
      margin-bottom: 0.5em; }
    .wizard > .content > .body label.error {
      color: #f05050;
      padding: 0;
      display: block; }
    .wizard > .content > .body .checkbox label.error {
      display: block !important; }

.wizard > .actions {
  position: relative;
  display: block;
  text-align: right;
  width: 100%; }
  .wizard > .actions > ul {
    display: inline-block;
    text-align: right; }
    .wizard > .actions > ul > li {
      margin: 0 0.5em; }

.wizard.vertical > .steps {
  display: inline; }
  .wizard.vertical > .steps > ul > li {
    float: none;
    width: 100%; }

.wizard.vertical > .content {
  display: inline;
  margin: 0 2.5% 0.5em 2.5%; }

.wizard.vertical > .actions {
  display: inline;
  margin: 0 2.5%; }
  .wizard.vertical > .actions > ul > li {
    margin: 0 0 0 1em; }

.wizard > .steps > ul > li,
.wizard > .actions > ul > li {
  float: left; }

.wizard > .steps a,
.wizard > .steps a:hover,
.wizard > .steps a:active {
  display: block;
  width: auto;
  margin: 0 0.5em 0.5em;
  padding: 1em 1em;
  text-decoration: none;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 0;
  font-weight: bold;
  color: #26314A; }
  .wizard > .steps a > small,
  .wizard > .steps a:hover > small,
  .wizard > .steps a:active > small {
    font-weight: normal;
    color: #909FA7; }

.wizard > .steps .disabled a,
.wizard > .steps .disabled a:hover,
.wizard > .steps .disabled a:active {
  background: #fcfcfc;
  color: #26314A;
  cursor: default; }

.wizard > .steps .current a,
.wizard > .steps .current a:hover,
.wizard > .steps .current a:active {
  background: #F95C1E;
  color: #fff;
  cursor: default; }
  .wizard > .steps .current a > small,
  .wizard > .steps .current a:hover > small,
  .wizard > .steps .current a:active > small {
    color: #fff; }

.wizard > .steps .done a,
.wizard > .steps .done a:hover,
.wizard > .steps .done a:active {
  background: #fcfcfc;
  color: #26314A; }

.wizard > .steps .error a,
.wizard > .steps .error a:hover,
.wizard > .steps .error a:active {
  background: #ff3111;
  color: #fff; }

.wizard > .actions a,
.wizard > .actions a:hover,
.wizard > .actions a:active {
  display: inline-block;
  padding: 6px 16px;
  font-size: 13px;
  border-radius: 0.25rem;
  text-decoration: none;
  color: #fff;
  background-color: #F95C1E;
  border-color: #F95C1E; }
  .wizard > .actions a:hover,
  .wizard > .actions a:hover:hover,
  .wizard > .actions a:active:hover {
    color: #fff;
    background-color: #ea4706;
    border-color: #de4306; }
  .wizard > .actions a:focus, .wizard > .actions a.focus,
  .wizard > .actions a:hover:focus,
  .wizard > .actions a:hover.focus,
  .wizard > .actions a:active:focus,
  .wizard > .actions a:active.focus {
    box-shadow: 0 0 0 0.2rem rgba(250, 116, 64, 0.5); }
  .wizard > .actions a.disabled, .wizard > .actions a:disabled,
  .wizard > .actions a:hover.disabled,
  .wizard > .actions a:hover:disabled,
  .wizard > .actions a:active.disabled,
  .wizard > .actions a:active:disabled {
    color: #fff;
    background-color: #F95C1E;
    border-color: #F95C1E; }
  .wizard > .actions a:not(:disabled):not(.disabled):active, .wizard > .actions a:not(:disabled):not(.disabled).active,
  .show > .wizard > .actions a.dropdown-toggle,
  .wizard > .actions a:hover:not(:disabled):not(.disabled):active,
  .wizard > .actions a:hover:not(:disabled):not(.disabled).active,
  .show >
  .wizard > .actions a:hover.dropdown-toggle,
  .wizard > .actions a:active:not(:disabled):not(.disabled):active,
  .wizard > .actions a:active:not(:disabled):not(.disabled).active,
  .show >
  .wizard > .actions a:active.dropdown-toggle {
    color: #fff;
    background-color: #de4306;
    border-color: #d23f06; }
    .wizard > .actions a:not(:disabled):not(.disabled):active:focus, .wizard > .actions a:not(:disabled):not(.disabled).active:focus,
    .show > .wizard > .actions a.dropdown-toggle:focus,
    .wizard > .actions a:hover:not(:disabled):not(.disabled):active:focus,
    .wizard > .actions a:hover:not(:disabled):not(.disabled).active:focus,
    .show >
    .wizard > .actions a:hover.dropdown-toggle:focus,
    .wizard > .actions a:active:not(:disabled):not(.disabled):active:focus,
    .wizard > .actions a:active:not(:disabled):not(.disabled).active:focus,
    .show >
    .wizard > .actions a:active.dropdown-toggle:focus {
      box-shadow: 0 0 0 0.2rem rgba(250, 116, 64, 0.5); }

.wizard > .actions .disabled a,
.wizard > .actions .disabled a:hover,
.wizard > .actions .disabled a:active {
  color: inherit !important;
  background-color: #dce7ed !important;
  box-shadow: 0 0 0 #000 !important; }

.tabcontrol > .steps {
  position: relative;
  display: block;
  width: 100%; }
  .tabcontrol > .steps > ul {
    position: relative;
    margin: 6px 0 0 0;
    top: 1px;
    z-index: 1; }
    .tabcontrol > .steps > ul > li {
      float: left;
      margin: 5px 2px 0 0;
      padding: 1px;
      -webkit-border-top-left-radius: 5px;
      -webkit-border-top-right-radius: 5px;
      -moz-border-radius-topleft: 5px;
      -moz-border-radius-topright: 5px;
      border-top-left-radius: 5px;
      border-top-right-radius: 5px; }
      .tabcontrol > .steps > ul > li > a {
        color: #5f5f5f;
        display: inline-block;
        border: 0 none;
        margin: 0;
        padding: 10px 30px;
        text-decoration: none; }
      .tabcontrol > .steps > ul > li > a:hover {
        text-decoration: none; }
    .tabcontrol > .steps > ul > li:hover {
      background: #edecec;
      border: 1px solid #bbb;
      padding: 0; }
    .tabcontrol > .steps > ul > li.current {
      background: #fff;
      border: 1px solid #bbb;
      border-bottom: 0 none;
      padding: 0 0 1px 0;
      margin-top: 0; }
      .tabcontrol > .steps > ul > li.current > a {
        padding: 15px 30px 10px 30px; }

.tabcontrol > .content {
  position: relative;
  display: inline-block;
  width: 100%;
  height: 300px;
  overflow: hidden;
  border-top: 1px solid #bbb;
  padding-top: 20px; }
  .tabcontrol > .content > .body {
    float: left;
    position: absolute;
    width: 95%;
    height: 95%;
    padding: 2.5%; }
    .tabcontrol > .content > .body ul {
      list-style: disc !important; }
      .tabcontrol > .content > .body ul > li {
        display: list-item; }

.spinner-wrapper {
  align-items: center !important;
  display: flex;
  height: 75px;
  justify-content: center !important;
  width: 100%; }
  .spinner-wrapper .ball-clip-rotate-multiple div {
    border-color: #F95C1E transparent !important; }

.link-light {
  color: #ffffff; }
  .link-light:hover, .link-light:focus {
    color: #F9C0AA; }

.modal-title button {
  margin-left: 15px; }

.modal-appointment .btn-video {
  display: flex;
  margin-bottom: .5rem;
  padding-top: 0;
  padding-bottom: 0; }
  .modal-appointment .btn-video .btn-labeled {
    display: flex;
    text-align: left; }
  .modal-appointment .btn-video .btn-text {
    text-align: center;
    padding-top: .4rem;
    margin-bottom: 0; }

.modal-appointment .modal-footer {
  display: block; }
  .modal-appointment .modal-footer button, .modal-appointment .modal-footer div {
    width: 100%;
    margin-bottom: .5rem; }
    .modal-appointment .modal-footer button.btn-labeled, .modal-appointment .modal-footer div.btn-labeled {
      display: flex;
      text-align: left; }
    .modal-appointment .modal-footer button.btn-text, .modal-appointment .modal-footer div.btn-text {
      text-align: center;
      padding-top: .4rem;
      margin-bottom: 0; }

.modal-appointment-data-col {
  min-width: 150px; }

.modal-appointment .close {
  position: absolute;
  right: 1rem; }

.react-select .react-select__control--is-focused {
  border-color: #F95C1E;
  box-shadow: 0 0 0 0.2rem rgba(249, 92, 30, 0.25); }
  .react-select .react-select__control--is-focused:hover, .react-select .react-select__control--is-focused:focus {
    border-color: #F95C1E; }

.react-select .react-select__option:hover {
  color: #26314A;
  background-color: #F9C0AA; }

.react-select .react-select__option--is-focused {
  background-color: #F9C0AA; }

.react-select .react-select__option--is-selected {
  background-color: #F95C1E;
  color: #ffffff; }
  .react-select .react-select__option--is-selected:hover {
    color: #ffffff;
    background-color: #E54607; }

.dropdown-toggle.btn {
  padding-top: 0.35rem;
  padding-bottom: 0.35rem; }

.navlist {
  list-style: none;
  margin-left: 0;
  margin-bottom: 20px;
  padding-left: 0; }
  @media (min-width: 576px) {
    .navlist {
      display: flex;
      flex-flow: row wrap;
      margin-left: -20px; } }

.navlist-item {
  display: block;
  margin-left: 0;
  margin-bottom: 10px; }
  @media (min-width: 576px) {
    .navlist-item {
      width: 50%;
      padding-left: 20px;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch; } }
  @media (min-width: 992px) {
    .navlist-item {
      width: calc(100% /3); } }

.navlist-link {
  display: block;
  width: 100%;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
  border-radius: 0.4rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  font-size: 1rem;
  font-weight: 600;
  color: #26314A; }
  .navlist-link:hover, .navlist-link:focus, .navlist-link:active {
    color: #F95C1E;
    text-decoration: none; }

.sidenav {
  list-style: none;
  padding-left: 0;
  margin-left: 0;
  margin-bottom: 20px; }
  .sidenav .sidenav {
    display: none;
    margin-left: 1em;
    margin-bottom: 0; }
  @media (min-width: 768px) {
    .sidenav-item.is-active > .sidenav {
      display: block; } }
  @media (max-width: 767.98px) {
    .sidenav {
      overflow-x: auto;
      overflow-y: hidden;
      display: flex;
      flex-flow: row nowrap;
      align-items: center;
      scroll-behavior: smooth;
      -webkit-overflow-scrolling: touch;
      padding-bottom: 0.5rem;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .sidenav .sidenav {
        display: none; } }

.sidenav-item {
  display: block; }
  @media (max-width: 767.98px) {
    .sidenav-item {
      flex: 0 1 auto; } }

.sidenav-link {
  display: block;
  color: #26314A;
  padding-top: 0.2em;
  padding-bottom: 0.2em; }
  @media (max-width: 767.98px) {
    .sidenav-link {
      padding-left: 10px;
      padding-right: 10px;
      padding-top: 10px;
      padding-bottom: 10px;
      white-space: nowrap; }
      .sidenav-item:first-child .sidenav-link {
        padding-left: 0; } }
  .sidenav-link:hover, .sidenav-link:focus, .sidenav-link:active {
    color: #F95C1E;
    text-decoration: none; }
  .sidenav-link.is-active,
  .sidenav-item.is-active > .sidenav-link {
    color: #F95C1E; }
    .sidenav-link.is-active:hover, .sidenav-link.is-active:focus, .sidenav-link.is-active:active,
    .sidenav-item.is-active > .sidenav-link:hover,
    .sidenav-item.is-active > .sidenav-link:focus,
    .sidenav-item.is-active > .sidenav-link:active {
      color: #E54607; }

.brick {
  margin-bottom: 20px;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  background-color: #ffffff;
  border-radius: 0.4rem;
  border: 1px solid rgba(0, 0, 0, 0.12);
  color: #909FA7; }
  @media (min-width: 576px) {
    .brick {
      display: flex;
      flex-flow: row nowrap; } }
  .brick-body .brick {
    margin-top: 20px; }

.brick-media {
  flex: 0 0 auto; }

.brick-content {
  flex: 1 1 auto; }

.brick-header {
  margin-top: -10px;
  margin-left: -10px;
  margin-right: -10px;
  padding: 10px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: space-between;
  align-items: center; }
  .brick-header:last-child {
    margin-bottom: -10px; }
  .brick-header[color="active"] {
    background-color: #edf2f9; }

.brick-headerend {
  margin-left: 20px; }

.brick-title {
  font-size: 1rem;
  font-weight: 600;
  color: #26314A; }
  .brick-header > .brick-title {
    margin-bottom: 0; }

.brick-actions {
  display: flex;
  flex-flow: row nowrap;
  align-items: center;
  justify-content: space-between; }
  .brick-actions:not(:first-child) {
    margin-top: 10px; }

.brick-actionsend:first-child:last-child {
  margin-left: auto; }

.progressbar {
  position: relative;
  display: block;
  margin-top: 30px;
  margin-bottom: 30px; }

.progressbar-bar {
  display: block;
  width: 100%;
  height: 6px;
  background-color: #dce7ed;
  border-radius: 3px;
  margin-top: 12px;
  margin-bottom: 12px; }

.progressbar-list {
  list-style: none;
  margin-left: 0;
  padding-left: 0;
  margin-bottom: 0;
  display: flex;
  flex-flow: row nowrap;
  counter-reset: progressbar; }

.progressbar-item {
  position: relative;
  z-index: 0;
  counter-increment: progressbar;
  margin-top: -30px;
  text-align: center;
  padding-left: 15px;
  padding-right: 15px; }
  .progressbar-item:first-child {
    padding-left: 0; }
  .progressbar-item.is-active::before {
    z-index: -1;
    display: block;
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 10px;
    height: 10px;
    background-color: #FF7F44; }
  .progressbar-item.is-active:first-child::before {
    border-radius: 5px 0 0 5px; }
  .progressbar-item.is-active .progressbar-number {
    background-color: #FF7F44; }
  .progressbar-item.is-current::before {
    z-index: -1;
    display: block;
    content: '';
    position: absolute;
    left: 0;
    right: 50%;
    top: 10px;
    height: 10px;
    background-color: #F95C1E;
    background-image: linear-gradient(to right, #FF7F44, #FF1A6B); }
  .progressbar-item.is-current:first-child::before {
    border-radius: 5px 0 0 5px; }
  .progressbar-item.is-current .progressbar-number {
    background-color: #FF1A6B;
    box-shadow: 0 0 0 3px rgba(255, 77, 140, 0.3); }
  .progressbar-item.is-current .progressbar-label {
    color: #26314A; }

.progressbar-number {
  display: block;
  margin-left: auto;
  margin-right: auto;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  line-height: 28px;
  text-align: center;
  font-weight: 600;
  background-color: #dce7ed;
  color: #ffffff; }
  .progressbar-number::before {
    content: counter(progressbar); }

.progressbar-label {
  display: block;
  color: #94A0B2; }

.choicegrid {
  margin-top: 10px;
  margin-bottom: -10px; }
  @media (min-width: 576px) {
    .choicegrid {
      display: flex;
      flex-flow: row wrap;
      align-items: stretch;
      margin-left: -20px; } }

.choicegrid-item {
  margin-bottom: 10px; }
  @media (min-width: 576px) {
    .choicegrid-item {
      padding-left: 20px;
      flex: 0 1 auto;
      width: 50%;
      display: flex;
      flex-flow: row nowrap;
      align-items: stretch; } }

.choicegrid-label {
  display: block;
  width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 10px;
  padding-bottom: 10px;
  margin-bottom: 0;
  display: inline-flex;
  align-items: flex-start;
  background-color: #edf2f9;
  border-radius: 0.4rem;
  color: #26314A;
  line-height: 1.25; }
  .choicegrid-label .checkbox {
    line-height: 1; }
    .choicegrid-label .checkbox > label {
      margin-bottom: 0; }

.choicegrid-title {
  font-size: 1rem;
  font-weight: 400;
  color: #26314A; }

/*!
 * https://github.com/YouCanBookMe/react-datetime
 */
#fc-datepicker .form-control {
  width: 100px;
  border-radius: 0;
  margin-left: 4px;
  background-color: #ffffff;
  text-align: center;
  font-weight: 600;
  cursor: pointer;
  padding-left: 8px;
  padding-right: 8px; }
  #fc-datepicker .form-control::placeholder {
    color: #26314A;
    font-weight: 600; }
  #fc-datepicker .form-control:hover, #fc-datepicker .form-control:focus {
    background-color: #edf2f9;
    border-color: #dce7ed; }
  #fc-datepicker .form-control:focus {
    box-shadow: 0 0 0 3px #dce7ed; }

#fc-datepicker .rdt {
  position: relative; }

#fc-datepicker .rdtPicker {
  display: none;
  position: absolute;
  width: 250px;
  padding: 0;
  margin-top: 1px;
  z-index: 99999 !important;
  background: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
  border: 0; }
  @media (max-width: 991.98px) {
    #fc-datepicker .rdtPicker {
      right: 0; } }

#fc-datepicker .rdtOpen .rdtPicker {
  display: block; }

#fc-datepicker .rdtStatic .rdtPicker {
  box-shadow: none;
  position: static; }

#fc-datepicker .rdtPicker .rdtTimeToggle {
  text-align: center; }

#fc-datepicker .rdtPicker table {
  width: 100%;
  margin: 0;
  table-layout: auto; }

#fc-datepicker .rdtPicker td,
#fc-datepicker .rdtPicker th {
  text-align: center;
  height: 28px; }

#fc-datepicker .rdtPicker td {
  cursor: pointer; }

#fc-datepicker .rdtPicker td.rdtDay:hover,
#fc-datepicker .rdtPicker td.rdtHour:hover,
#fc-datepicker .rdtPicker td.rdtMinute:hover,
#fc-datepicker .rdtPicker td.rdtSecond:hover,
#fc-datepicker .rdtPicker .rdtTimeToggle:hover {
  background: #eeeeee;
  cursor: pointer; }

#fc-datepicker .rdtPicker td.rdtOld,
#fc-datepicker .rdtPicker td.rdtNew {
  color: #999999; }

#fc-datepicker .rdtPicker td.rdtToday {
  position: relative; }

#fc-datepicker .rdtPicker td.rdtToday:before {
  content: '';
  display: inline-block;
  border-left: 7px solid transparent;
  border-bottom: 7px solid #F95C1E;
  border-top-color: rgba(0, 0, 0, 0.2);
  position: absolute;
  bottom: 4px;
  right: 4px; }

#fc-datepicker .rdtPicker td.rdtActive,
#fc-datepicker .rdtPicker td.rdtActive:hover {
  background-color: #F95C1E;
  color: #fff;
  text-shadow: 0 -1px 0 rgba(0, 0, 0, 0.25); }

#fc-datepicker .rdtPicker td.rdtActive.rdtToday:before {
  border-bottom-color: #fff; }

#fc-datepicker .rdtPicker td.rdtDisabled,
#fc-datepicker .rdtPicker td.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

#fc-datepicker .rdtPicker td span.rdtOld {
  color: #999999; }

#fc-datepicker .rdtPicker td span.rdtDisabled,
#fc-datepicker .rdtPicker td span.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

#fc-datepicker .rdtPicker th {
  border-bottom: 1px solid #f9f9f9; }

#fc-datepicker .rdtPicker .dow {
  width: 14.2857%;
  border-bottom: none;
  cursor: default; }

#fc-datepicker .rdtPicker th.rdtSwitch {
  width: 100px;
  vertical-align: middle; }

#fc-datepicker .rdtPicker th.rdtNext,
#fc-datepicker .rdtPicker th.rdtPrev {
  font-size: 21px;
  vertical-align: top; }

#fc-datepicker .rdtPrev span,
#fc-datepicker .rdtNext span {
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

#fc-datepicker .rdtPicker th.rdtDisabled,
#fc-datepicker .rdtPicker th.rdtDisabled:hover {
  background: none;
  color: #999999;
  cursor: not-allowed; }

#fc-datepicker .rdtPicker thead tr:first-child th {
  cursor: pointer; }

#fc-datepicker .rdtPicker thead tr:first-child th:hover {
  background: #eeeeee; }

#fc-datepicker .rdtPicker tfoot {
  border-top: 1px solid #f9f9f9; }

#fc-datepicker .rdtPicker button {
  border: none;
  background: none;
  cursor: pointer; }

#fc-datepicker .rdtPicker button:hover {
  background-color: #eee; }

#fc-datepicker .rdtPicker thead button {
  width: 100%;
  height: 100%; }

#fc-datepicker td.rdtMonth,
#fc-datepicker td.rdtYear {
  height: 50px;
  width: 25%;
  cursor: pointer; }

#fc-datepicker td.rdtMonth:hover,
#fc-datepicker td.rdtYear:hover {
  background: #eee; }

#fc-datepicker .rdtCounters {
  display: inline-block; }

#fc-datepicker .rdtCounters > div {
  float: left; }

#fc-datepicker .rdtCounter {
  height: 100px; }

#fc-datepicker .rdtCounter {
  width: 40px; }

#fc-datepicker .rdtCounterSeparator {
  line-height: 100px; }

#fc-datepicker .rdtCounter .rdtBtn {
  height: 40%;
  line-height: 40px;
  cursor: pointer;
  display: block;
  -webkit-touch-callout: none;
  /* iOS Safari */
  -webkit-user-select: none;
  /* Chrome/Safari/Opera */
  -khtml-user-select: none;
  /* Konqueror */
  -moz-user-select: none;
  /* Firefox */
  -ms-user-select: none;
  /* Internet Explorer/Edge */
  user-select: none; }

#fc-datepicker .rdtCounter .rdtBtn:hover {
  background: #eee; }

#fc-datepicker .rdtCounter .rdtCount {
  height: 20%;
  font-size: 1.2em; }

#fc-datepicker .rdtMilli {
  vertical-align: middle;
  padding-left: 8px;
  width: 48px; }

#fc-datepicker .rdtMilli input {
  width: 100%;
  font-size: 1.2em;
  margin-top: 37px; }

#fc-datepicker .rdtTime td {
  cursor: default; }

.button-group {
  display: flex;
  margin-bottom: 1rem;
  border-bottom: 1px solid lightgray; }
  .button-group div {
    margin-bottom: 1rem; }
  @media (max-width: 991.98px) {
    .button-group {
      flex-flow: wrap; } }
  .button-group .btn {
    width: 100%;
    display: flex;
    text-align: left;
    margin-right: 1rem;
    min-width: 125px; }
    .button-group .btn .btn-text {
      width: 100%;
      text-align: center;
      padding-top: 0.4rem; }
    @media (max-width: 991.98px) {
      .button-group .btn {
        min-width: 100px;
        margin-right: 0.5rem;
        overflow: hidden;
        padding-right: 2px;
        padding-left: 0; }
        .button-group .btn:nth-child(even) {
          margin-right: 0; }
        .button-group .btn .btn-label {
          left: 0; }
        .button-group .btn .btn-text {
          text-overflow: ellipsis;
          overflow: hidden; } }
    .button-group .btn.last-child, .button-group .btn:last-child {
      margin-right: 0; }

/*a.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end {
    height: 40px;
}*/
div#close-event-button {
  position: absolute;
  top: 0;
  right: 2px;
  z-index: 999; }
  div#close-event-button a {
    color: #000000; }

.context-menu {
  z-index: 999; }
  .context-menu-item {
    margin-right: 5px; }

a.fc-event .fc-content {
  overflow: initial; }

.fc-day-grid .fc-content {
  padding: 5px; }

.fc-all-day-event {
  border: none;
  text-align: center; }

.calender-types .fc-event-container {
  padding: 2px; }

.calender-types a.fc-time-event {
  border: none !important;
  text-align: center !important;
  font-size: 1.2em; }

.calender-types .fc-event-background {
  opacity: .9 !important;
  border-radius: 5px; }

div.event-priority {
  width: 50px;
  background: white;
  color: black;
  display: block;
  float: left;
  margin-right: 10px;
  text-align: center;
  border: 1px solid lightgray;
  position: relative;
  cursor: pointer;
  padding-right: 5px; }
  div.event-priority i {
    position: absolute;
    top: 3px;
    right: 5px; }

ul.event-priority-list {
  list-style: none;
  padding: 0;
  display: none;
  z-index: 999; }
  ul.event-priority-list li {
    cursor: pointer;
    width: 50px;
    background: white;
    color: black;
    padding-right: 5px;
    text-align: right; }
    ul.event-priority-list li:hover {
      background: #ebebeb; }

#calendar {
  display: flex;
  height: 100%; }

.fc.fc-unthemed .fc-toolbar h2 {
  font-weight: 700;
  font-size: 18px;
  color: #55657F; }

.fc.fc-unthemed .fc-toolbar .fc-button {
  padding-top: 0.2em;
  padding-bottom: 0.2em;
  padding-left: 0.5em;
  padding-right: 0.5em; }
  .fc.fc-unthemed .fc-toolbar .fc-button > .fc-icon {
    font-size: 1.3em;
    position: relative;
    top: -1px; }

.fc.fc-unthemed .fc-toolbar.fc-header-toolbar {
  margin-bottom: 15px; }

.fc.fc-unthemed .fc-button-group {
  box-shadow: 0 1px 2px rgba(221, 230, 233, 0.45); }

.fc.fc-unthemed .fc-button {
  background-color: #ffffff;
  border-color: #dce7ed;
  color: #26314A;
  font-weight: 600;
  font-size: 14px; }
  .fc.fc-unthemed .fc-button:hover, .fc.fc-unthemed .fc-button:focus {
    background-color: #edf2f9;
    border-color: #dde6e9;
    color: #26314A; }
  .fc.fc-unthemed .fc-button:focus {
    box-shadow: 0 0 0 0.2rem rgba(221, 230, 233, 0.8) !important; }
  .fc.fc-unthemed .fc-button.fc-button-active {
    color: #ffffff;
    background-color: #F95C1E;
    border-color: #F95C1E; }

.fc.fc-unthemed th,
.fc.fc-unthemed td,
.fc.fc-unthemed thead,
.fc.fc-unthemed tbody {
  border-color: rgba(85, 101, 127, 0.1); }

.fc.fc-unthemed td:first-child,
.fc.fc-unthemed th:first-child {
  border-left-width: 0; }

.fc.fc-unthemed td:last-child,
.fc.fc-unthemed th:last-child {
  border-right-width: 0; }

.fc.fc-unthemed td.fc-today,
.fc.fc-unthemed th.fc-today {
  background-color: rgba(85, 101, 127, 0.05) !important;
  border-right-color: rgba(85, 101, 127, 0.2);
  border-left-color: rgba(85, 101, 127, 0.2); }

.fc.fc-unthemed .fc-divider {
  background-color: rgba(85, 101, 127, 0.2);
  border: 0; }

.fc.fc-unthemed .fc-time-grid .fc-now-indicator-line {
  border-top-width: 0;
  height: 2px;
  background-color: #F95C1E;
  box-shadow: 0 1px 2px rgba(229, 70, 7, 0.4);
  right: calc(7% - 2px);
  left: -2px; }

.fc.fc-unthemed .fc-time-grid .fc-now-indicator-arrow {
  left: 2px;
  border: 0;
  width: 10px;
  height: 8px;
  border-radius: 2px;
  background-color: #F95C1E;
  margin-top: -3px;
  box-shadow: 0 1px 2px rgba(249, 92, 30, 0.4); }
  .fc.fc-unthemed .fc-time-grid .fc-now-indicator-arrow::before {
    display: block;
    margin-top: 3px;
    width: 18px;
    height: 2px;
    content: "";
    background-color: #F95C1E;
    box-shadow: 0 1px 2px rgba(249, 92, 30, 0.4); }
  .fc.fc-unthemed .fc-time-grid .fc-now-indicator-arrow::after {
    position: absolute;
    z-index: -1;
    top: 5px;
    display: none;
    width: 17px;
    height: 0;
    content: "";
    width: 100vw;
    border-top: 2px dashed rgba(249, 92, 30, 0.25); }

.fc.fc-unthemed .fc-head {
  margin-bottom: 5px;
  border: 0 !important; }
  .fc.fc-unthemed .fc-head .fc-head-container {
    border-radius: 0.4rem;
    background-color: #edf2f9; }
  .fc.fc-unthemed .fc-head td,
  .fc.fc-unthemed .fc-head th {
    border-top-width: 0;
    font-size: 14px;
    color: #26314A;
    padding-top: 0.1em;
    padding-bottom: 0.1em;
    border-width: 0;
    border-left-width: 1px;
    border-right-width: 1px;
    border-top-width: 0; }
  .fc.fc-unthemed .fc-head td:first-child {
    border-left-width: 0; }
  .fc.fc-unthemed .fc-head td:last-child {
    border-right-width: 0; }
  .fc.fc-unthemed .fc-head th {
    font-weight: 600; }
    .fc.fc-unthemed .fc-head th:first-child {
      border-left-width: 0; }
    .fc.fc-unthemed .fc-head th:last-child {
      border-right-width: 0; }
  .fc.fc-unthemed .fc-head .fc-row {
    border-width: 0 !important; }

.fc.fc-unthemed .fc-bg {
  font-size: 14px;
  color: #94A0B2; }

.fc.fc-unthemed .fc-widget-content {
  border-top-width: 0;
  border-bottom-width: 0; }

.fc.fc-unthemed .fc-slats {
  font-size: 14px;
  color: #94A0B2; }
  .fc.fc-unthemed .fc-slats td {
    height: 2rem; }
  .fc.fc-unthemed .fc-slats tr:nth-child(odd) {
    background-color: rgba(85, 101, 127, 0.05); }
  .fc.fc-unthemed .fc-slats tr:last-child td,
  .fc.fc-unthemed .fc-slats tr:last-child th {
    border-bottom-width: 0; }
  .fc.fc-unthemed .fc-slats .fc-axis {
    background-color: transparent; }

.fc.fc-unthemed .fc-time-grid .fc-event-container {
  margin-right: 7%;
  margin-left: 1px; }

.fc.fc-unthemed .fc-event {
  border-width: 0;
  border-style: solid;
  border-radius: 0.25rem;
  z-index: 0;
  border-top-width: 5px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.05) !important;
  border-left: 1px solid rgba(0, 0, 0, 0.05) !important;
  border-right: 1px solid rgba(0, 0, 0, 0.05) !important;
  padding-left: 3px;
  background-color: rgba(255, 255, 255, 0.9);
  color: #26314A;
  margin-bottom: 7px;
  box-shadow: 1px 2px 3px rgba(85, 101, 127, 0.1);
  padding-bottom: 2px; }
  .fc.fc-unthemed .fc-event:hover {
    box-shadow: 1px 2px 5px rgba(85, 101, 127, 0.2); }
  .fc.fc-unthemed .fc-event .fc-content {
    overflow: hidden; }
  .fc.fc-unthemed .fc-event .fc-resizer {
    font-size: 13px;
    background-color: rgba(236, 243, 255, 0.6);
    color: #55657F;
    left: 2px;
    right: 2px;
    bottom: -5px;
    border-radius: 2px; }
  .fc.fc-unthemed .fc-event .fc-event-background {
    opacity: 0.2;
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    display: none; }
  .fc.fc-unthemed .fc-event.fc-free-event {
    background-color: rgba(255, 255, 255, 0.7); }
    .fc.fc-unthemed .fc-event.fc-free-event .fc-event-background {
      display: block; }
  .fc.fc-unthemed .fc-event .fc-time {
    white-space: nowrap;
    overflow: hidden; }
    .fc.fc-unthemed .fc-event .fc-time > span {
      font-size: 13px;
      font-weight: 600;
      color: rgba(38, 49, 74, 0.55); }
  .fc.fc-unthemed .fc-event .fc-status {
    position: relative;
    top: 2px;
    display: inline-block;
    margin-left: 4px;
    margin-right: 4px;
    height: 11px;
    min-width: 5px;
    border-radius: 11px;
    padding-left: 3px;
    padding-right: 3px;
    line-height: 11px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase; }
  .fc.fc-unthemed .fc-event .fc-status-code {
    display: block;
    letter-spacing: 0.5px;
    max-width: 30px;
    white-space: nowrap;
    overflow: hidden; }
  .fc.fc-unthemed .fc-event .fc-title {
    font-weight: 600;
    font-size: 14px;
    color: #26314A;
    line-height: 1.2;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis; }

tr#fullcalendar-sub-header a {
  color: #ffffff; }

.fc.fc-unthemed .fc-event .fc-title {
  white-space: normal; }
