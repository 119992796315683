
.progressbar {
    position: relative;
    display: block;
    margin-top: $content-padding *2;
    margin-bottom: $content-padding *2;
}

.progressbar-bar {
    display: block;
    width: 100%;
    height: 6px;
    background-color: $gray-lighter;
    border-radius: 3px;
    margin-top: 12px;
    margin-bottom: 12px;
}

.progressbar-list {
    list-style: none;
    margin-left: 0;
    padding-left: 0;
    margin-bottom: 0;
    
    display: flex;
    flex-flow: row nowrap;

    counter-reset: progressbar;
}

.progressbar-item {
    position: relative;
    z-index: 0;
    counter-increment: progressbar;
    margin-top: -30px;
    text-align: center;
    padding-left: $content-padding;
    padding-right: $content-padding;

    &:first-child {
        padding-left: 0;
    }

    &.is-active {
        &::before {
            z-index: -1;
            display: block;
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 10px;
            height: 10px;
            background-color: $primary-light;
        }

        &:first-child::before {
            border-radius: 5px 0 0 5px;
        }

        .progressbar-number {
            background-color: $primary-light;
        }
    }

    &.is-current {
        &::before {
            z-index: -1;
            display: block;
            content: '';
            position: absolute;
            left: 0;
            right: 50%;
            top: 10px;
            height: 10px;
            background-color: $primary;
            background-image: linear-gradient(to right, $primary-light, $brand);
        }

        &:first-child::before {
            border-radius: 5px 0 0 5px;
        }

        .progressbar-number {
            background-color: $brand;
            box-shadow: 0 0 0 3px rgba($brand-light, 0.3);
        }

        .progressbar-label {
            color: $body-color;
        }
    }
}

.progressbar-number {
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 30px;
    height: 30px;
    border-radius: 50%;

    line-height: 28px;
    text-align: center;

    font-weight: 600;

    background-color: $gray-lighter;
    color: $white;

    &::before {
        content: counter(progressbar);
    }
}

.progressbar-label {
    display: block;
    color: $subsidiary-lighter;
}