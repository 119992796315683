.brick {
    margin-bottom: $grid-gutter-width;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    padding-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;
    background-color: $white;
    border-radius: $border-radius;
    border: 1px solid $border-color;
    color: $text-muted;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-flow: row nowrap;
    }

    .brick-body & {
        margin-top: $grid-gutter-width;
    }
}

.brick-media {
    flex: 0 0 auto;
}

.brick-content {
    flex: 1 1 auto;
}

.brick-header {
    margin-top: -$grid-gutter-width / 2;
    margin-left: -$grid-gutter-width / 2;
    margin-right: -$grid-gutter-width / 2;
    padding: $grid-gutter-width / 2;

    display: flex;
    flex-flow: row nowrap;
    justify-content: space-between;
    align-items: center;

    &:last-child {
        margin-bottom: -$grid-gutter-width / 2;
    }

    &[color="active"] {
        background-color: $gray-lightest;
    }
}

.brick-headerend {
    margin-left: $grid-gutter-width;
}

.brick-title {
    font-size: $font-size-base;
    font-weight: 600;
    color: $body-color;

    .brick-header > & {
        margin-bottom: 0;
    }
}

.brick-body {}

.brick-actions {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: space-between;

    &:not(:first-child) {
        margin-top: $grid-gutter-width / 2;
    }
}

.brick-actionsend {
    &:first-child:last-child {
        margin-left: auto;
    }
}
