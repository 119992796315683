.modal-title {
    button {
        margin-left: 15px;
    }
}

.modal-appointment {
    .btn-video{
        display: flex;
        margin-bottom: .5rem;
        padding-top: 0;
        padding-bottom: 0;

        .btn-labeled {
            display: flex;
            text-align: left;
        }
        .btn-text {
            text-align: center;
            padding-top: .4rem;
            margin-bottom: 0;
        }
    }

    .modal-footer {
        display: block;


        @include media-breakpoint-down(xl) {
            button, div {
                width: 100%;
                margin-bottom: .5rem;

                &.btn-labeled {
                    display: flex;
                    text-align: left;
                }
                &.btn-text {
                    text-align: center;
                    padding-top: .4rem;
                    margin-bottom: 0;
                }
            }
        }
    }

    &-data-col {
        min-width: 150px;
    }

    .close {
        position: absolute;
        right: 1rem;
    }
}