.choicegrid {
    margin-top: $grid-gutter-width / 2;
    margin-bottom: -$grid-gutter-width / 2;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-flow: row wrap;
        align-items: stretch;
        margin-left: -$grid-gutter-width;
    }
}

.choicegrid-item {
    margin-bottom: $grid-gutter-width /2;
    
    @include media-breakpoint-up(sm) {
        padding-left: $grid-gutter-width;
        flex: 0 1 auto;
        width: 50%;

        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
    }
}

.choicegrid-label {
    display: block;
    width: 100%;
    padding-left: $grid-gutter-width / 2;
    padding-right: $grid-gutter-width / 2;
    padding-top: $grid-gutter-width / 2;
    padding-bottom: $grid-gutter-width / 2;
    margin-bottom: 0;
    
    display: inline-flex;
    align-items: flex-start;

    background-color: $gray-lightest;
    border-radius: $border-radius;
    color: $body-color;
    line-height: 1.25;

    .checkbox {
        line-height: 1;

        > label {
            margin-bottom: 0;
        }
    }
}

.choicegrid-title {
    font-size: $font-size-base;
    font-weight: 400;
    color: $body-color;
}
