.heading {
    display: grid;
    align-items: center;
    margin: -$content-padding;
    margin-bottom: 20px;
    padding: $content-padding $content-padding;
    font-weight: normal;
    background-color: $content-heading-bg;

    min-height: 54px;

    &.heading-nomargin {
        margin-bottom: 0;
    }

    @include media-breakpoint-up(xl) {
        display: flex;
    }
}

.heading-start {
    margin-bottom: 15px;
    @include media-breakpoint-up(xl) {
        margin-bottom: 0;
    }
}

.heading-end {
    margin-left: auto;

    > * {
        float: left;
    }
}

.heading-title {
    line-height: $headings-line-height;
    margin-bottom: 0;
    font-size: 21px;
    color: $subsidiary;
    font-weight: 600;
}

.heading-subtitle {
    font-size: 16px;
    color: $subsidiary-lighter;
    font-weight: 500;
    margin-bottom: 0;
}
