.sidenav {
    list-style: none;
    padding-left: 0;
    margin-left: 0;
    margin-bottom: $grid-gutter-width;

    .sidenav {
        display: none;
        margin-left: 1em;
        margin-bottom: 0;
    }

    @include media-breakpoint-up(md) {
        .sidenav-item.is-active > & {
            display: block;
        }
    }


    @include media-breakpoint-down(sm) {
        overflow-x: auto;
        overflow-y: hidden;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        scroll-behavior: smooth;
        -webkit-overflow-scrolling: touch;
        padding-bottom: 0.5rem;
        border-bottom: 1px solid $border-color;

        .sidenav {
            display: none;
        }
    }
}

.sidenav-item {
    display: block;

    @include media-breakpoint-down(sm) {
        flex: 0 1 auto;
    }
}

.sidenav-link {
    display: block;
    color: $body-color;
    padding-top: 0.2em;
    padding-bottom: 0.2em;

    @include media-breakpoint-down(sm) {
        padding-left: $grid-gutter-width / 2;
        padding-right: $grid-gutter-width / 2;
        padding-top: $grid-gutter-width / 2;
        padding-bottom: $grid-gutter-width / 2;
        white-space: nowrap;

        .sidenav-item:first-child & {
            padding-left: 0;
        }
    }


    @include hover-focus-active {
        color: $primary;
        text-decoration: none;
    }


    &.is-active,
    .sidenav-item.is-active > & {
        color: $primary;

        @include hover-focus-active {
            color: $primary-dark;
        }
    }
}
