@import '~@fullcalendar/core/main.css';
@import '~@fullcalendar/daygrid/main.css';
@import "~@fullcalendar/timegrid/main.css";

/*a.fc-day-grid-event.fc-h-event.fc-event.fc-start.fc-end {
    height: 40px;
}*/

div#close-event-button {
    position: absolute;
    top: 0;
    right: 2px;
    z-index: 999;

    a {
        color: #000000;
    }
}

.context-menu {
    z-index: 999;

    &-item {
        margin-right: 5px;
    }
}

a.fc-event {
    .fc-content {
        overflow: initial;
    }
}

.fc-day-grid {
    .fc-content {
        padding: 5px;
    }
}

.fc-all-day-event {
    border: none;
    text-align: center;
}

.calender-types {
    .fc-event-container {
        padding: 2px;
    }

    a.fc-time-event {
        border: none !important;
        text-align: center !important;
        font-size: 1.2em;
    }

    .fc-event-background {
        opacity: .9 !important;
        border-radius: 5px;
    }
}

div.event-priority {
    width: 50px;
    background: white;
    color: black;
    display: block;
    float: left;
    margin-right: 10px;
    text-align: center;
    border: 1px solid lightgray;
    position: relative;
    cursor: pointer;
    padding-right: 5px;
    
    i {
        position: absolute;
        top: 3px;
        right: 5px;
    }
}

ul.event-priority-list {
    list-style: none;
    padding: 0;
    display: none;
    z-index: 999;

    li {
        cursor: pointer;
        width: 50px;
        background: white;
        color: black;
        padding-right: 5px;
        text-align: right;

        &:hover {
           background: rgb(235, 235, 235); 
        }
    }
}