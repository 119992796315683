.navlist {
    list-style: none;
    margin-left: 0;
    margin-bottom: $grid-gutter-width;

    padding-left: 0;

    @include media-breakpoint-up(sm) {
        display: flex;
        flex-flow: row wrap;

        margin-left: -$grid-gutter-width;
    }
}

.navlist-item {
    display: block;
    margin-left: 0;
    margin-bottom: $grid-gutter-width /2;

    @include media-breakpoint-up(sm) {
        width: 50%;
        padding-left: $grid-gutter-width;

        display: flex;
        flex-flow: row nowrap;
        align-items: stretch;
    }

    @include media-breakpoint-up(lg) {
        width: calc(100% /3);
    }
}

.navlist-link {
    display: block;
    width: 100%;

    padding-left: $grid-gutter-width;
    padding-right: $grid-gutter-width;
    padding-top: $grid-gutter-width /2;
    padding-bottom: $grid-gutter-width /2;

    background-color: $white;
    border-radius: $border-radius;
    border: 1px solid $border-color;

    font-size: $font-size-base;
    font-weight: 600;
    color: $body-color;

    @include hover-focus-active {
        color: $primary;
        text-decoration: none;
    }
}